import { addLeadingZero } from './numbers';

export function timeToSeconds(time) {
   let [hours, minutes, seconds] = time.split(':');
   seconds = parseInt(seconds) || 0;
   minutes = parseInt(minutes) || 0;
   seconds = parseInt(seconds) || 0;
   return seconds + minutes * 60 + hours * 3600;
}

/**
 *
 * @param {number} time
 * @return {Object}
 */
export function secondsToClockItems(time) {
   const hours = Math.floor(time / 3600);
   const minutes = Math.floor((time - hours * 3600) / 60);
   const seconds = time - (minutes * 60 + hours * 3600);
   return {
      hours,
      minutes,
      seconds
   };
}

/**
 *
 * @param {number} time
 * @return {string}
 */
export function secondsToClockTime(time) {
   const { seconds, minutes, hours } = secondsToClockItems(time);
   return [
      addLeadingZero(hours),
      addLeadingZero(minutes),
      addLeadingZero(seconds)
   ].join(':');
}
