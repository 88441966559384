import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTimePicker } from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Alert from "@material-ui/lab/Alert";

import AppContext from "../AppContext";
import { dateTimeFormat } from "../utilities/date";

const propTypes = {
  values: PropTypes.shape({
    patient: PropTypes.number,
    goal: PropTypes.object,
    user: PropTypes.number,
    registration_time: PropTypes.object,
    value: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  goals: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  valueToggle: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  succeededButton: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
    },
  },
  succeededButtonSelected: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main + " !important",
  },
  failedButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
    },
  },
  failedButtonSelected: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main + " !important",
  },
  emergentButton: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastText,
    },
  },
  emergentButtonSelected: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main + " !important",
  },
}));

function Form({ values, setValues, goals, children }) {
  const { patientList, userList } = useContext(AppContext);
  const setValue = (value) => {
    setValues({ ...values, ...value });
  };

  const patientId = values.patient || "";

  const handleValueChange = (event, value) => {
    setValue({ value });
  };
  const handlePatientChange = (event) => {
    const { value } = event.target;
    setValue({ patient: value });
  };

  const classes = useStyles();

  return (
    <>
      <form autoComplete="off">
        <TextField
          id="patient"
          select
          fullWidth
          variant="outlined"
          label="Utente"
          placeholder="Seleziona un utente"
          margin="normal"
          value={patientId}
          onChange={handlePatientChange}
          required
        >
          {patientList.map((option) => {
            const selected = values.patient === option.id;
            return (
              <MenuItem key={option.id} value={option.id} selected={selected}>
                {`${option.first_name} ${option.last_name}`}
              </MenuItem>
            );
          })}
        </TextField>

        {goals.length === 0 && (
          <Alert severity="warning">
            Non ci sono obiettivi assegnati a questo utente, crearne almeno uno
            per procedere
          </Alert>
        )}

        {goals.length > 0 && (
          <Autocomplete
            id="goal"
            options={goals}
            getOptionLabel={(option) => option.title || ""}
            getOptionSelected={(option, value) => option.id === value.id}
            value={values.goal || ""}
            disableClearable={true}
            onChange={(e, value) => {
              setValue({
                goal: value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                label="Obiettivo"
                placeholder="Seleziona un Obiettivo"
                margin="normal"
                required
              />
            )}
          />
        )}

        <TextField
          id="user"
          select
          fullWidth
          variant="outlined"
          label="Operatore"
          placeholder="Seleziona un operatore"
          margin="normal"
          required
          value={values.user || ""}
          onChange={(e) => {
            setValue({ user: parseInt(e.target.value) });
          }}
        >
          {userList.map((option) => {
            const selected = values.user === option.id;
            return (
              <MenuItem key={option.id} value={option.id} selected={selected}>
                {`${option.first_name} ${option.last_name}`}
              </MenuItem>
            );
          })}
        </TextField>

        <DateTimePicker
          locale={itLocale}
          fullWidth
          inputVariant="outlined"
          ampm={false}
          format={dateTimeFormat}
          margin="normal"
          label="Data e Ora registrazione"
          value={values.registration_time}
          onChange={(value) => {
            setValue({
              registration_time: value,
            });
          }}
        />

        <div className={classes.valueToggle}>
          <Typography gutterBottom>Esito</Typography>
          <ToggleButtonGroup
            value={values.value}
            exclusive
            onChange={handleValueChange}
            aria-label="Esito"
            required
          >
            <ToggleButton
              value="succeeded"
              aria-label="Riuscito"
              classes={{
                root: classes.succeededButton,
                selected: classes.succeededButtonSelected,
              }}
            >
              Riuscito
            </ToggleButton>
            <ToggleButton
              value="emergent"
              aria-label="Emergente"
              classes={{
                root: classes.emergentButton,
                selected: classes.emergentButtonSelected,
              }}
            >
              Emergente
            </ToggleButton>
            <ToggleButton
              value="failed"
              aria-label="Fallito"
              classes={{
                root: classes.failedButton,
                selected: classes.failedButtonSelected,
              }}
            >
              Fallito
            </ToggleButton>
            <ToggleButton
              value="not_classifiable"
              aria-label="Non classificabile"
            >
              Non classificabile
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <TextField
          id="notes"
          label="Note"
          multiline
          margin="normal"
          fullWidth
          rows={4}
          variant="outlined"
          value={values.notes}
          onChange={(event) => {
            setValue({
              notes: event.target.value,
            });
          }}
        />

        {children}
      </form>
    </>
  );
}

Form.propTypes = propTypes;

export default Form;
