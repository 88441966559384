import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TimerIcon from '@material-ui/icons/Timer';
import PauseIcon from '@material-ui/icons/Pause';
import RestoreIcon from '@material-ui/icons/Restore';
import { timeToSeconds, secondsToClockTime } from '../utilities/time';

const useStyles = makeStyles(theme => ({
   root: {
      paddingLeft: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      position: 'relative'
   },
   controls: {
      display: 'flex',
      alignItems: 'center'
   },
   time: {
      fontSize: 26,
      fontFamily: 'monospace',
      border: 'none'
   },
   active: {
      color: theme.palette.primary.main
   },
   timerIcon: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(2),
      position: 'absolute',
      left: 0
   },
   playIcon: {
      height: 38,
      width: 38
   }
}));

export default function Timer({ time, setTime }) {
   const classes = useStyles();
   const [isPlaying, setIsPlaying] = useState(false);
   const [timerInterval, setTimerInterval] = useState(null);

   useEffect(() => {
      if (isPlaying) {
         const timerInterval = setInterval(() => {
            setTime(time => time + 1);
         }, 1000);
         setTimerInterval(timerInterval);
      } else {
         if (timerInterval) clearInterval(timerInterval);
         setTimerInterval(null);
      }
   }, [isPlaying]);

   const vibrationDuration = 100;

   const startPlay = () => {
      if ('vibrate' in window.navigator)
         window.navigator.vibrate(vibrationDuration);
      setIsPlaying(true);
   };
   const stopPlay = () => {
      if ('vibrate' in window.navigator)
         window.navigator.vibrate(vibrationDuration);
      setIsPlaying(false);
   };
   const reset = () => {
      stopPlay();
      setTime(0);
   };
   const timeValue = secondsToClockTime(time);

   const handleTimeChange = e => {
      const seconds = timeToSeconds(e.target.value);
      setTime(seconds);
   };

   return (
      <Card className={classes.root}>
         <div className={classes.controls}>
            <div className={classes.timerIcon}>
               <TimerIcon />
            </div>
            {!isPlaying ? (
               <IconButton
                  aria-label="play"
                  onClick={() => {
                     startPlay();
                  }}
               >
                  <PlayArrowIcon className={classes.playIcon} />
               </IconButton>
            ) : (
               <IconButton
                  aria-label="pause"
                  onClick={() => {
                     stopPlay();
                  }}
               >
                  <PauseIcon className={classes.playIcon} />
               </IconButton>
            )}
            <IconButton aria-label="reset" onClick={reset}>
               <RestoreIcon />
            </IconButton>
         </div>
         <div>
            <input
               id="time"
               type="time"
               step="1"
               value={timeValue}
               className={clsx(classes.time, isPlaying ? classes.active : {})}
               onChange={handleTimeChange}
            />
         </div>
      </Card>
   );
}

Timer.propTypes = {
   time: PropTypes.number.isRequired,
   setTime: PropTypes.func.isRequired
};
