import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChecklistExecutionService from "../services/ChecklistExecutionService";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { formattedDate } from "../utilities/date";
import AppContext from "../AppContext";
import { green, yellow, red, grey } from "../theme";
import { secondsToClockTime } from "../utilities/time";

// icons
import CreateIcon from "@material-ui/icons/Create";
import PersonIcon from "@material-ui/icons/Person";
import EventIcon from "@material-ui/icons/Event";
import TimerIcon from "@material-ui/icons/Timer";
import DeleteIcon from "@material-ui/icons/Delete";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const cardBackgroundColor = "primary";
const headerPadding = 2;
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(12),
    paddingLeft: theme.spacing(headerPadding),
    paddingRight: theme.spacing(headerPadding),
    paddingBottom: theme.spacing(headerPadding),
    marginTop: -theme.spacing(2),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    background: `linear-gradient(145deg, ${theme.palette[cardBackgroundColor].light} 0%, ${theme.palette[cardBackgroundColor].dark} 100%);`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    color: theme.palette[cardBackgroundColor].contrastText,
  },
  editButton: {
    marginTop: theme.spacing(2),
  },
  succeeded: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  },
  emergent: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  },
  failed: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  nc: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.warning.main,
  },
  chart: {
    margin: "0 auto",
  },
}));

function ChecklistExecutionDetails({ match }) {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const { executionId } = match.params;
  const [execution, setExecution] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [checklistExecutionToDelete, setChecklistExecutionToDelete] =
    useState();
  const history = useHistory();

  useEffect(() => {
    ChecklistExecutionService.get(executionId).then((data) => {
      setExecution(data);
    });
  }, [executionId]);

  if (!execution) return <LinearProgress />;

  const getHeaderStyle = () => {
    const { cover } = execution.checklist;
    if (!cover) return {};
    return {
      backgroundImage: `linear-gradient(
                        rgba(0, 0, 0, 0.2) 0%, 
                        rgba(0, 0, 0, 0.5) 100%
                        ), url(${cover})`,
    };
  };

  const results = execution.task_results.map((result) => {
    return {
      ...result,
      task: result.task,
    };
  });

  const statistics = results.reduce((acc, result) => {
    const value = acc[result.value] ? acc[result.value] + 1 : 1;
    acc[result.value] = value;
    return acc;
  }, {});

  const translations = {
    succeeded: "Riusciti",
    emergent: "Emergenti",
    failed: "Falliti",
    not_classifiable: "Non classificabile",
  };
  const order = ["succeeded", "emergent", "failed", "not_classifiable"];
  const data = Object.keys(statistics)
    .map((type) => {
      const value = statistics[type];
      return {
        type,
        value,
        name: translations[type],
      };
    })
    .sort((a, b) => {
      const aIndex = order.findIndex((item) => item === a.type);
      const bIndex = order.findIndex((item) => item === b.type);
      if (aIndex > bIndex) return 1;
      if (aIndex < bIndex) return -1;
      return 0;
    });

  const COLORS = data.map((item) => {
    switch (item.type) {
      case "succeeded":
        return green;
      case "emergent":
        return yellow;
      case "failed":
        return red;
      case "not_classifiable":
      default:
        return grey;
    }
  });

  const patient = appContext.getPatient(execution.patient);
  const goToPatientDetails = () => {
    history.push(`/app/patients/${patient.id}`);
  };
  const GoToChecklistExecutionEdit = () => {
    history.push(`/app/checklist-executions/${execution.id}/edit`);
  };

  const deleteChecklistExecution = (id) => {
    ChecklistExecutionService.delete(id).then(() => {
      history.goBack();
    });
  };

  return (
    <>
      <div className={classes.header} style={getHeaderStyle()}>
        <Typography variant="h5">{execution.checklist.title} </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Button
              size="small"
              color="inherit"
              startIcon={<PersonIcon />}
              onClick={goToPatientDetails}
            >
              {`${patient.first_name} ${patient.last_name}`}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button size="small" color="inherit" startIcon={<EventIcon />}>
              {formattedDate(execution.start_time)}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button size="small" color="inherit" startIcon={<PeopleAltIcon />}>
              {`${execution.user.first_name} ${execution.user.last_name}`}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button size="small" color="inherit" startIcon={<TimerIcon />}>
              {secondsToClockTime(execution.time_taken)}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={6}>
          <Button
            onClick={GoToChecklistExecutionEdit}
            variant="contained"
            color="primary"
            startIcon={<CreateIcon />}
            className={classes.editButton}
          >
            Modifica
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Box textAlign="right">
            <Button
              onClick={() => {
                setShowDeleteConfirmation(true);
                setChecklistExecutionToDelete(execution);
              }}
              variant="contained"
              color="primary"
              startIcon={<DeleteIcon />}
              className={classes.editButton}
            >
              Elimina
            </Button>
          </Box>
        </Grid>
      </Grid>
      <PieChart width={400} height={100} className={classes.chart}>
        <Pie
          data={data}
          cx={200}
          cy={100}
          startAngle={180}
          endAngle={0}
          innerRadius={60}
          outerRadius={90}
          paddingAngle={5}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <List>
        {results.map((result, index) => {
          return (
            <ListItem key={result.id}>
              <ListItemAvatar>
                <Avatar className={classes[result.value]}>{index + 1}</Avatar>
              </ListItemAvatar>
              {result.task.title}
            </ListItem>
          );
        })}
      </List>
      <Dialog
        open={showDeleteConfirmation}
        onClose={setShowDeleteConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sei sicuro di voler cancellare l'esecuzione di questa prova?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDeleteConfirmation(false);
            }}
            color="primary"
            autoFocus
          >
            Annulla
          </Button>
          <Button
            onClick={() => {
              deleteChecklistExecution(checklistExecutionToDelete.id);
            }}
            color="primary"
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChecklistExecutionDetails;
