import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, LinearProgress, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import AppContext from "../AppContext";
import GoalService from "../services/GoalService";
import GoalResultService from "../services/GoalResultService";
import Form from "./Form";

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

function GoalResultRegistration(props) {
  const { match, location } = props;
  let { patientId } = match.params;
  const params = queryString.parse(location.search);
  const { loggedUser } = useContext(AppContext);
  const defaultValues = {
    registration_time: new Date(),
    goal: null,
  };

  defaultValues.user = params.user ? parseInt(params.user) : loggedUser.user_id;
  defaultValues.patient = parseInt(patientId);

  const [goals, setGoals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const classes = useStyles();

  const [values, setValues] = useState(defaultValues);
  const history = useHistory();

  useEffect(() => {
    if (values.patient) {
      patientId = values.patient;
    }
    if (patientId) {
      setIsLoading(true);
      setGoals([]);
      GoalService.getByPatient(patientId).then((response) => {
        setGoals(response);
        const goal = params.goal ? parseInt(params.goal) : response[0];
        setValues({
          ...values,
          goal,
        });
        setIsLoading(false);
      });
    }
  }, [params.goal, params.user, patientId, values.patient]);

  const validateForm = () => {
    if (!values.value) return false;
    if (!values.goal) return false;
    return true;
  };

  useEffect(() => {
    setFormIsValid(validateForm());
  }, [values.value, values.goal]);

  if (isLoading) return <LinearProgress />;

  const handleSubmit = () => {
    GoalResultService.save({
      goal: values.goal.id,
      registration_time: values.registration_time,
      user: values.user,
      value: values.value,
      note: values.notes,
    })
      .then(() => {
        history.push(`/app/patients/${values.patient}/goal-results`);
      })
      .catch((error) => {
        // TODO: add error with snackbar (ex. ChecklistEdit.jsx)
        console.error("Error saving goal result", error);
      });
  };

  return (
    <>
      <Typography variant="h6">Registra l'esito di un obiettivo</Typography>
      <Form values={values} setValues={setValues} goals={goals}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.submitBtn}
          onClick={handleSubmit}
          disabled={!formIsValid}
        >
          Registra
        </Button>
      </Form>
    </>
  );
}

export default GoalResultRegistration;
