import React, { useState, useEffect, useContext } from "react";
import { Typography, LinearProgress, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ChecklistService from "../services/ChecklistService";
import queryString from "query-string";
import { getUnixTime } from "date-fns";
import AppContext from "../AppContext";
import Form from "./Form";

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    marginTop: theme.spacing(3),
  },
}));

function ChecklistSubmit({ location }) {
  const params = queryString.parse(location.search);
  const { patientList, loggedUser } = useContext(AppContext);
  const defaultValues = {
    date: new Date(),
  };

  const [checklists, setChecklists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  const [values, setValues] = useState(defaultValues);
  const history = useHistory();

  useEffect(() => {
    ChecklistService.all().then((checklistResponse) => {
      setChecklists(checklistResponse);

      defaultValues.user = params.user
        ? parseInt(params.user)
        : loggedUser.user_id;
      defaultValues.patient = params.patient
        ? parseInt(params.patient)
        : patientList[0].id;
      defaultValues.checklist = params.checklist
        ? parseInt(params.checklist)
        : checklistResponse[0].id;

      setIsLoading(false);
    });
  }, [params.checklist, params.user, params.patient]);

  if (isLoading) return <LinearProgress />;

  const handleSubmit = () => {
    const params = Object.keys(values).reduce((acc, key) => {
      let value = values[key];
      if (key === "date") value = getUnixTime(value);
      acc[key] = value;
      return acc;
    }, {});
    history.push({
      pathname: "/app/checklist",
      search: "?" + queryString.stringify(params),
    });
  };

  return (
    <>
      <Typography variant="h6">Compila una prova</Typography>
      <Form values={values} setValues={setValues} checklists={checklists}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.submitBtn}
          onClick={handleSubmit}
        >
          Inizia
        </Button>
      </Form>
    </>
  );
}

export default ChecklistSubmit;
