import React, { useEffect, useState, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  LinearProgress,
  ListItemAvatar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Popover,
  Box,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ErrorIcon from "@material-ui/icons/Error";
import DeleteIcon from "@material-ui/icons/Delete";
import NotesIcon from "@material-ui/icons/Notes";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { formatDistance } from "date-fns";
import itLocale from "date-fns/locale/it";
import { makeStyles } from "@material-ui/core/styles";
import {
  orange,
  deepOrange,
  green,
  yellow,
  red,
} from "@material-ui/core/colors";

import ChallengingBehaviorRegistrationService from "../services/ChallengingBehaviorRegistrationService";
import {
  formattedDate,
  formattedDistance,
  dateFromServer,
} from "../utilities/date";
import AppContext from "../AppContext";

const useStyles = makeStyles((theme) => ({
  green: {
    color: green[500],
  },
  yellow: {
    color: yellow[500],
  },
  orange: {
    color: orange[500],
  },
  deepOrange: {
    color: deepOrange[500],
  },
  red: {
    color: red[700],
  },
  textRight: {
    textAlign: "right",
  },
  notePopover: {
    padding: theme.spacing(2),
  },
}));

function getIntensityClass(intensity) {
  switch (intensity) {
    case 1:
      return "green";
    case 2:
      return "yellow";
    case 3:
      return "orange";
    case 4:
      return "deepOrange";
    case 5:
      return "red";
  }
}

function ChallengingBehaviorRegistrationList(props) {
  const { match } = props;
  const { patientId } = match.params;
  const classes = useStyles();
  const [registrations, setRegistrations] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [registrationToDelete, setRegistrationToDelete] = useState(null);

  useEffect(() => {
    ChallengingBehaviorRegistrationService.getByFilter({
      patient: patientId,
    }).then((data) => {
      setRegistrations(data);
    });
  }, [patientId]);

  const openDeleteDialog = (id) => {
    setRegistrationToDelete(id);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => {
    setRegistrationToDelete(null);
    setShowDeleteDialog(false);
  };

  const handleDeleteRegistration = () => {
    ChallengingBehaviorRegistrationService.delete(registrationToDelete)
      .then(() => {
        closeDeleteDialog();
        removeChallengingRegistrationFromList(registrationToDelete);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeChallengingRegistrationFromList = (id) => {
    const registrationIndex = registrations.findIndex((r) => r.id === id);
    let newList = [...registrations];
    newList.splice(registrationIndex, 1);
    setRegistrations(newList);
  };

  if (!registrations) return <LinearProgress />;

  if (registrations.length === 0)
    return (
      <>
        <Alert severity="warning">
          Nessun comportamento problema registrato su questo utente
        </Alert>
      </>
    );

  return (
    <>
      <Typography variant="h6">Comportamenti Problema</Typography>
      <List>
        {registrations.map((registration) => {
          const {
            id,
            challenging_behavior,
            start_time,
            end_time,
            intensity,
            notes,
          } = registration;
          const startDateStr = `${formattedDistance(
            start_time
          )} (${formattedDate(start_time, "EEEEEE dd/MM/yyyy HH:mm:ss")})`;
          const duration = formatDistance(
            dateFromServer(start_time),
            dateFromServer(end_time),
            {
              includeSeconds: true,
              locale: itLocale,
            }
          );

          return (
            <span key={id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <ErrorIcon
                    className={classes[getIntensityClass(intensity)]}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={challenging_behavior.title}
                  secondary={startDateStr}
                />
                <div className={classes.textRight}>
                  <div>{duration}</div>
                  <div>
                    {notes && (
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <span>
                            <IconButton
                              edge="end"
                              aria-label="Note"
                              {...bindTrigger(popupState)}
                            >
                              <NotesIcon />
                            </IconButton>
                            <Popover {...bindPopover(popupState)}>
                              <Box p={2}>
                                <Typography>{notes}</Typography>
                              </Box>
                            </Popover>
                          </span>
                        )}
                      </PopupState>
                    )}
                    <IconButton
                      edge="end"
                      aria-label="Elimina"
                      onClick={() => {
                        openDeleteDialog(registration.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </ListItem>
              <Divider variant="inset" component="li" />
            </span>
          );
        })}
      </List>
      <Dialog open={showDeleteDialog} onClose={closeDeleteDialog}>
        <DialogTitle>
          Sei sicuro di voler cancellare questa registrazione?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Una volta eliminata non sarà più possibile recuperarla
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteRegistration} color="primary">
            Conferma
          </Button>
          <Button onClick={closeDeleteDialog} color="primary" autoFocus>
            Annulla
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChallengingBehaviorRegistrationList;
