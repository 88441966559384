import { createMuiTheme } from "@material-ui/core/styles";

export const green = "#00C49F";
export const yellow = "#FFBB28";
export const red = "#FF8042";
export const grey = "#bdbdbd";
export const blue = "#199DF0";
export const rustyRed = "#D7263D";
export const white = "#FFFFFF";

const theme = createMuiTheme({
  palette: {
    primary: { main: blue, contrastText: white },
    secondary: { main: rustyRed },
    error: { main: red },
    warning: { main: yellow },
    info: { main: grey },
    success: { main: green },
  },
  props: {
    MuiListItem: {
      dense: false,
    },
  },
  typography: {
    fontSize: 15,
  },
});

export default theme;
