import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ChecklistService from '../services/ChecklistService';
import ChecklistExecutionService from '../services/ChecklistExecutionService';
import { format, fromUnixTime } from 'date-fns';
import { serverDateTimeFormat } from '../utilities/date';
import Slider from './Slider';
import Timer from '../Timer/Timer';

const useStyles = makeStyles(theme => ({
   submitBtn: {
      marginTop: theme.spacing(3)
   }
}));

export default function ChecklistCompilation({ location }) {
   const params = queryString.parse(location.search);
   const [checklist, setChecklist] = useState({});
   const [isLoading, setIsLoading] = useState(true);
   const [taskResults, setTaskResults] = useState({});
   const [activeStep, setActiveStep] = useState(0);
   const [time, setTime] = useState(0);
   const history = useHistory();

   useEffect(() => {
      ChecklistService.get(params.checklist).then(response => {
         setChecklist(response);
         setIsLoading(false);
      });
   }, [params.checklist]);

   const handleSubmit = () => {
      const endTime = format(new Date(), serverDateTimeFormat);
      const startDate = fromUnixTime(params.date) || new Date();
      const startTime = format(startDate, serverDateTimeFormat);
      const taskResultList = Object.keys(taskResults).map(id => {
         const value = taskResults[id].value;
         return {
            task_id: id,
            value
         };
      });
      const data = {
         checklist: checklist.id,
         start_time: startTime,
         end_time: endTime,
         time_taken: time,
         user: params.user,
         patient: params.patient,
         task_results: taskResultList
      };
      ChecklistExecutionService.save(data).then(response => {
         if (response.status === 200)
            history.push(
               `/app/patients/${params.patient}/checklists/${checklist.id}/executions/${response.data.id}`
            );
      });
   };

   const classes = useStyles();

   if (isLoading) return <LinearProgress />;

   return (
      <>
         <Typography variant="h6">Compilazione prova</Typography>
         <Typography variant="h5">{checklist.title}</Typography>

         <Slider
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            tasks={checklist.tasks}
            taskResults={taskResults}
            setTaskResults={setTaskResults}
         />

         <Timer time={time} setTime={setTime} />

         <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submitBtn}
            onClick={handleSubmit}
            disabled={
               Object.keys(taskResults).length !== checklist.tasks.length
            }
         >
            Salva risultato
         </Button>
      </>
   );
}
