import React, { useEffect, useState } from 'react';
import ChecklistService from '../services/ChecklistService';
import {
   LinearProgress,
   Typography,
   Button,
   Snackbar
} from '@material-ui/core';
import Alert from '../Alert/Alert';
import ChecklistForm from '../ChecklistForm/ChecklistForm';
import ChecklistCategoryService from '../services/ChecklistCategoryService';

export default function ChecklistEdit({ match }) {
   const { checklistId } = match.params;
   const [checklist, setChecklist] = useState('loading');
   const [title, setTitle] = useState('');
   const [category, setCategory] = useState();
   const [categoryOptions, setCategoryOptions] = useState([]);
   const [estimatedTime, setEstimatedTime] = useState('');
   const [cover, setCover] = useState('');
   const [tasks, setTasks] = useState([]);
   const [showSnackbar, setShowSnackbar] = useState(false);
   const [snackbar, setSnackbar] = useState({
      message: '',
      severity: ''
   });

   useEffect(() => {
      ChecklistCategoryService.all().then(response => {
         setCategoryOptions(response);
      });
      ChecklistService.get(checklistId).then(response => {
         setChecklist(response);
         setTitle(response.title);
         setCategory(response.category ? response.category.id : '');
         setEstimatedTime(response.estimated_time);
         setCover(response.cover || '');
         setTasks(
            response.tasks.map(task => ({
               id: task.id,
               title: task.title
            }))
         );
      });
   }, [checklistId]);

   const handleCloseSnackbar = () => {
      setShowSnackbar(false);
   };

   const update = () => {
      ChecklistService.update(
         checklistId,
         title,
         estimatedTime,
         tasks,
         category
      )
         .then(response => {
            setSnackbar({
               message: 'Prova aggiornata correttamente',
               severity: 'success'
            });
            setShowSnackbar(true);
         })
         .catch(error => {
            console.error(error);
            setSnackbar({
               message: 'Si è verificato un errore',
               severity: 'error'
            });
            setShowSnackbar(true);
         });
   };

   if (checklist === 'loading') return <LinearProgress />;

   return (
      <>
         <Typography variant="h5" gutterBottom>
            Aggiorna prova
         </Typography>
         <ChecklistForm
            id={checklist.id}
            title={title}
            setTitle={setTitle}
            category={category}
            setCategory={setCategory}
            categoryOptions={categoryOptions}
            estimatedTime={estimatedTime}
            setEstimatedTime={setEstimatedTime}
            tasks={tasks}
            setTasks={setTasks}
            cover={cover}
            setCover={setCover}
            submitButton={
               <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={update}
               >
                  Aggiorna
               </Button>
            }
         />
         <Snackbar
            open={showSnackbar}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
         >
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
               {snackbar.message}
            </Alert>
         </Snackbar>
      </>
   );
}
