import React from "react";

const CustomizedDot = (props) => {
  const { cx, cy, value } = props;
  let color;
  if (props.color) {
    color = props.color;
  } else if (value === 1) {
    color = "#FF8042";
  } else if (value === 2) {
    color = "#FFBB28";
  } else if (value === 3) {
    color = "#00C49F";
  }

  const width = 12;

  return (
    <svg
      viewBox="0 0 100 100"
      width={width}
      height={width}
      x={cx - width / 2}
      y={cy - width / 2}
    >
      <circle cx="50" cy="50" r="50" fill={color} />
    </svg>
  );
};

export default CustomizedDot;
