import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import ChecklistForm from "../ChecklistForm/ChecklistForm";
import ChecklistService from "../services/ChecklistService";
import ChecklistCategoryService from "../services/ChecklistCategoryService";

export default function ChecklistCreation() {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [estimatedTime, setEstimatedTime] = useState("");
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    ChecklistCategoryService.all().then((response) => {
      setCategoryOptions(response);
    });
  }, []);

  const create = () => {
    ChecklistService.save(title, estimatedTime, tasks, category).then(
      (response) => {
        history.push(`/app/checklist-edit/${response}`);
      }
    );
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Crea una nuova prova
      </Typography>
      <ChecklistForm
        title={title}
        setTitle={setTitle}
        category={category}
        setCategory={setCategory}
        categoryOptions={categoryOptions}
        estimatedTime={estimatedTime}
        setEstimatedTime={setEstimatedTime}
        tasks={tasks}
        setTasks={setTasks}
        submitButton={
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={create}
          >
            Crea
          </Button>
        }
      />
    </>
  );
}
