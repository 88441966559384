import React, { useEffect, useCallback } from "react";
import { TextField, List, Button, Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddIcon from "@material-ui/icons/Add";
import theme from "../theme";
import TaskItem from "./TaskItem";
import ChecklistService from "../services/ChecklistService";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  textInput: {
    marginBottom: theme.spacing(2),
  },
  checklistForm: {
    marginBottom: theme.spacing(10),
  },
  cover: {
    width: "100%",
  },
}));

export default function ChecklistForm({
  id,
  title,
  setTitle,
  category,
  setCategory,
  categoryOptions,
  estimatedTime,
  setEstimatedTime,
  tasks,
  setTasks,
  cover,
  setCover,
  submitButton,
}) {
  const addTask = useCallback(() => {
    const newTaskList = [...tasks];
    newTaskList.push({ id: 0, title: "" });
    setTasks(newTaskList);
  }, [tasks, setTasks]);

  const setTask = useCallback(
    (index, value) => {
      const newTaskList = [...tasks];
      newTaskList[index] = value;
      setTasks(newTaskList);
    },
    [tasks, setTasks]
  );

  const removeTask = useCallback(
    (index) => {
      const newTaskList = [...tasks];
      newTaskList.splice(index, 1);
      setTasks(newTaskList);
    },
    [tasks, setTasks]
  );

  useEffect(() => {
    addTask();
  }, []);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newTaskList = reorder(
      tasks,
      result.source.index,
      result.destination.index
    );

    setTasks(newTaskList);
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? theme.palette.success.light : "transparent",
  });

  const classes = useStyles();

  const updateCover = (image) => {
    ChecklistService.updateCover(id, image);
    setCover(image);
  };

  return (
    <div className={classes.checklistForm}>
      <TextField
        required
        maxLength="250"
        id="title"
        label="Titolo"
        variant="outlined"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        fullWidth
        className={classes.textInput}
      />
      <TextField
        id="category"
        select
        fullWidth
        label="Categoria"
        variant="outlined"
        placeholder="Seleziona una categoria"
        value={category || ""}
        className={classes.textInput}
        onChange={(e) => {
          setCategory(parseInt(e.target.value));
        }}
      >
        {categoryOptions.map((option) => {
          const selected = category === option.id;
          return (
            <MenuItem key={option.id} value={option.id} selected={selected}>
              {option.title}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        id="estimated_time"
        label="Tempo stimato (minuti)"
        type="number"
        variant="outlined"
        value={estimatedTime}
        onChange={(e) => {
          setEstimatedTime(e.target.value);
        }}
        fullWidth
        className={classes.textInput}
      />

      {id && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {cover ? (
              <img
                src={
                  typeof cover === "string" ? cover : URL.createObjectURL(cover)
                }
                className={classes.cover}
                alt="Checklist cover"
              />
            ) : (
              <Alert severity="warning">Nessuna immagine associata</Alert>
            )}
          </Grid>
          <Grid item xs>
            <Button variant="contained" color="primary" component="label">
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  updateCover(e.target.files[0]);
                }}
                id="cover"
                accept="image/jpeg"
              />
              Carica immagine
            </Button>
          </Grid>
        </Grid>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tasks">
          {(provided, snapshot) => (
            <List
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {tasks.map((task, index) => {
                return (
                  <TaskItem
                    task={task}
                    index={index}
                    setTask={setTask}
                    removeTask={removeTask}
                    key={index}
                  />
                );
              })}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            onClick={() => {
              addTask();
            }}
            variant="outlined"
            startIcon={<AddIcon />}
            color="primary"
            fullWidth
          >
            Aggiungi
          </Button>
        </Grid>
        <Grid item xs={6}>
          {submitButton}
        </Grid>
      </Grid>
    </div>
  );
}
