import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PatientList from "../PatientList/PatientList";
import ChecklistSubmit from "../ChecklistSubmit/ChecklistSubmit";
import ChecklistCompilation from "../ChecklistCompilation/ChecklistCompilation";
import PatientDetails from "../PatientDetails/PatientDetails";
import ChecklistExecutionDetails from "../ChecklistExecutionDetails/ChecklistExecutionDetails";
import ChecklistCreation from "../ChecklistCreation/ChecklistCreation";
import ChecklistExecutionEdit from "../ChecklistExecutionEdit/ChecklistExecutionEdit";
import Login from "../Login/Login";
import ChecklistEdit from "../ChecklistEdit/ChecklistEdit";
import ChecklistManagement from "../ChecklistManagement/ChecklistManagement";
import ChallengingBehaviorRegistration from "../ChallengingBehaviorRegistration/ChallengingBehaviorRegistration";
import PageNotFound from "../PageNotFound/PageNotFound";
import GoalResultRegistration from "../GoalResultRegistration/GoalResultRegistration";
import HandoverList from "../HandoverList/HandoverList";
import HandoverRegistration from "../HandoverRegistration/HandoverRegistration";

function Routes(props) {
  return (
    <Switch>
      <Route path="/app/login" component={Login} />
      <PrivateRoute path="/" exact component={PatientList} />
      <PrivateRoute path="/app/patients" component={PatientList} exact />
      <PrivateRoute
        path="/app/patients/:patientId/checklists/:checklistId/executions/:executionId"
        component={ChecklistExecutionDetails}
        exact
      />

      <PrivateRoute
        path="/app/checklist-executions/:executionId/edit"
        component={ChecklistExecutionEdit}
      />
      <PrivateRoute
        path="/app/checklist-executions/:executionId"
        component={ChecklistExecutionDetails}
        exact
      />
      <PrivateRoute
        path="/app/patients/:patientId/goal-results/registration"
        component={GoalResultRegistration}
        exact
      />
      <PrivateRoute
        path="/app/patients/:patientId/handover-creation"
        component={HandoverRegistration}
        exact
      />
      <PrivateRoute
        path="/app/patients/:patientId/handovers/:handoverId"
        component={HandoverRegistration}
        exact
      />
      <PrivateRoute
        path="/app/patients/:patientId"
        component={PatientDetails}
      />
      <PrivateRoute path="/app/checklist-submit" component={ChecklistSubmit} />
      <PrivateRoute path="/app/checklist" component={ChecklistCompilation} />
      <PrivateRoute
        path="/app/checklist-management"
        component={ChecklistManagement}
      />
      <PrivateRoute
        path="/app/checklist-creation"
        component={ChecklistCreation}
      />
      <PrivateRoute
        path="/app/checklist-edit/:checklistId"
        component={ChecklistEdit}
      />
      <PrivateRoute
        path="/app/challenging-behavior-registration"
        component={ChallengingBehaviorRegistration}
      />
      <PrivateRoute
        path="/app/goal-results/registration"
        component={GoalResultRegistration}
        exact
      />
      <PrivateRoute path="/app/handovers" component={HandoverList} exact />
      <PrivateRoute
        path="/app/handover-creation"
        component={HandoverRegistration}
        exact
      />
      <PrivateRoute
        path="/app/handovers/:handoverId"
        component={HandoverRegistration}
      />

      <Route path="*" exact={true} component={PageNotFound} />
    </Switch>
  );
}

export default Routes;
