import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LinearProgress, TextField, MenuItem, Grid } from "@material-ui/core";

import GoalResultService from "../services/GoalResultService";
import GoalService from "../services/GoalService";
import GoalResultOverallRadar from "../GoalResultOverallRadar/GoalResultOverallRadar";
import {
  getCurrentMonth,
  getCurrentYear,
  getMonthOptions,
  getYearOptions,
} from "../utilities/date";

GoalResultOverallStatistics.propTypes = {
  patientId: PropTypes.string.isRequired,
};

function _prepareDataForRadar(results, goals) {
  let goalMap = goals.reduce((map, goal) => {
    map[goal.title] = { score: 0, total: 0 };
    return map;
  }, {});
  goalMap = results.reduce((map, result) => {
    const { count, goal__title: goal, value } = result;

    map[goal]["total"] += count;

    if (value === "failed") return map;

    const score = value === "emergent" ? count * 0.5 : count;
    map[goal]["score"] += score;

    return map;
  }, goalMap);

  let data = Object.keys(goalMap).map((goal) => {
    const { score, total } = goalMap[goal];
    const finalScore = total === 0 ? 0 : Math.round((score * 100) / total);
    return { goal, score: finalScore };
  });

  data.sort((a, b) => {
    if (a.score > b.score) return 1;
    if (a.score < b.score) return -1;
    return 0;
  });

  return data;
}

const currentMonth = getCurrentMonth();
const currentYear = getCurrentYear();

const monthOptions = getMonthOptions({ addAllOption: true });
const yearOptions = getYearOptions({ addAllOption: true });

function GoalResultOverallStatistics(props) {
  const { patientId } = props;
  const [data, setData] = useState(null);
  const [goals, setGoals] = useState(null);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  useEffect(() => {
    GoalService.getByPatient(patientId).then((goals) => {
      setGoals(goals);
      const params = {
        month,
        year,
      };
      GoalResultService.getOverallStatistics(patientId, params).then((data) => {
        setData(_prepareDataForRadar(data.count, goals));
      });
    });
  }, [patientId]);

  useEffect(() => {
    if (!goals) return;
    const params = {
      month: month === "all" ? null : month,
      year: year === "all" ? null : year,
    };
    GoalResultService.getOverallStatistics(patientId, params).then((data) => {
      setData(_prepareDataForRadar(data.count, goals));
    });
  }, [month, year]);

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  if (!data) return <LinearProgress />;

  return (
    <div>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item xs={6}>
          <TextField
            id="month"
            select
            fullWidth
            label="Mese"
            margin="normal"
            value={month}
            onChange={handleMonthChange}
          >
            {monthOptions.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="year"
            select
            fullWidth
            label="Anno"
            margin="normal"
            value={year}
            onChange={handleYearChange}
          >
            {yearOptions.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <GoalResultOverallRadar data={data} />
    </div>
  );
}

export default GoalResultOverallStatistics;
