export function stringToRGB(str) {
   var hash = 0;
   for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
   }
   var r = hash >> 16;
   var g = (hash >> 8) & 255;
   var b = hash & 255;
   return [r, g, b];
}

export function contrastTextFromRGB(rgb) {
   const o = Math.round(
      (parseInt(rgb[0]) * 299 +
         parseInt(rgb[1]) * 587 +
         parseInt(rgb[2]) * 114) /
         1000
   );

   const textColor = o > 125 ? 'black' : 'whte';
   return textColor;
}
