import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

const useCustomStepIconStyles = makeStyles(theme => ({
   step: {
      color: 'inherit',
      border: `solid 3px #ccc`,
      backgroundColor: 'transparent'
   },
   succeeded: {
      color: 'inherit',
      borderColor: theme.palette.success.main
   },
   emergent: {
      color: 'inherit',
      borderColor: theme.palette.warning.main
   },
   failed: {
      color: 'inherit',
      borderColor: theme.palette.error.main
   },
   active: {
      fontWeight: 'bold'
   }
}));

export default function StepIcon(props) {
   const classes = useCustomStepIconStyles();
   const { active, completed, icon, taskResult } = props;
   const classList = [classes.step];

   if (completed) {
      const className = taskResult ? taskResult.value : '';
      classList.push(classes[className]);
   }
   if (active) classList.push(classes.active, 'active');

   return <Avatar className={classList.join(' ')}>{icon}</Avatar>;
}
