import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Snackbar, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { format } from "date-fns";

import { serverDateTimeFormat } from "../utilities/date";
import Alert from "../Alert/Alert";
import AppContext from "../AppContext";
import HandoverService from "../services/HandoverService";
import Form from "./Form";
import { dateFromServer } from "../utilities/date";

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

function HandoverRegistration(props) {
  const { match, location } = props;
  let { patientId, handoverId } = match.params;
  const params = queryString.parse(location.search);
  const { loggedUser, patientList } = useContext(AppContext);
  const defaultValues = {
    start_time: new Date(),
    end_time: new Date(),
    note: "",
  };

  defaultValues.user = params.user ? parseInt(params.user) : loggedUser.user_id;
  defaultValues.patient = patientId ? parseInt(patientId) : patientList[0].id;

  const history = useHistory();
  const classes = useStyles();

  const [formIsValid, setFormIsValid] = useState(false);
  const [values, setValues] = useState(defaultValues);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "",
  });

  const validateForm = () => {
    if (!values.note) return false;
    return true;
  };

  useEffect(() => {
    if (!handoverId) return;
    HandoverService.get(handoverId).then((data) => {
      setValues({
        start_time: dateFromServer(data.start_time),
        end_time: dateFromServer(data.end_time),
        note: data.note,
        user: data.user.id,
        patient: data.patient.id,
      });
    });
  }, []);

  useEffect(() => {
    setFormIsValid(validateForm());
  }, [values.note]);

  const handleSubmit = () => {
    const data = {
      start_time: format(values.start_time, serverDateTimeFormat),
      end_time: format(values.end_time, serverDateTimeFormat),
      user_id: values.user,
      patient_id: values.patient,
      note: values.note,
    };
    const caller = handoverId
      ? () => HandoverService.update(handoverId, data)
      : () => HandoverService.save(data);

    caller()
      .then(() => {
        if (patientId)
          history.push(`/app/patients/${values.patient}/handovers`);
        else history.push(`/app/handovers`);
      })
      .catch((error) => {
        console.error(
          "Error saving handover",
          error.message,
          error.response.data
        );
        setSnackbar({
          message: "Si è verificato un errore",
          severity: "error",
        });
        setShowSnackbar(true);
      });
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <>
      <Typography variant="h6">
        {handoverId ? "Aggiorna consegna" : "Registra una consegna"}
      </Typography>
      <Form values={values} setValues={setValues}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.submitBtn}
          onClick={handleSubmit}
          disabled={!formIsValid}
        >
          Salva
        </Button>
      </Form>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default HandoverRegistration;
