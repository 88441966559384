import axios from "axios";

export default class ChecklistExecutionService {
  static async all() {
    const response = await axios.get("/api/checklist-executions");
    return response.data;
  }

  static async save(data) {
    return await axios.post(`/save-checklist-execution`, data);
  }

  static async update(id, data) {
    return await axios.put(`/update-checklist-execution/${id}/`, data);
  }

  static async get(pk) {
    const url = `/api/checklist-executions/${pk}/`;
    const response = await axios.get(url);
    return response.data;
  }

  static async getList(params) {
    const url = `/checklist-execution-list`;
    const response = await axios.get(url, {
      params,
    });
    return response.data;
  }

  static async getStatistics(params) {
    const url = `/checklist-statistics`;
    params.month = params.month || null;
    params.year = params.year || null;
    const response = await axios.get(url, {
      params,
    });
    return response.data;
  }

  static async delete(id) {
    const url = `/api/checklist-executions/${id}/`;
    return await axios.delete(url);
  }
}
