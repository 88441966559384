import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  MenuItem,
  Slider,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTimePicker } from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";

import AppContext from "../AppContext";
import { dateTimeFormat } from "../utilities/date";
import ExpectedPatientChallengingBehaviorService from "../services/ExpectedPatientChallengingBehaviorService";

const propTypes = {
  values: PropTypes.shape({
    patient: PropTypes.number,
    challenging_behavior: PropTypes.object,
    user: PropTypes.number,
    start_date: PropTypes.object,
    end_date: PropTypes.object,
    intensity: PropTypes.number,
    notes: PropTypes.string,
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  challengingBehaviors: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  intensityField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

function Form({ values, setValues, challengingBehaviors, children }) {
  const { patientList, userList } = useContext(AppContext);
  const [expectedChallengingBehaviorList, setExpectedChallengingBehaviorList] =
    useState([]);
  const [
    showOnlyExpectedChallengingBehaviors,
    setShowOnlyExpectedChallengingBehaviors,
  ] = useState(true);
  const [filteredChallengingBehaviors, setFilteredChallengingBehaviors] =
    useState([]);
  const setValue = (value) => {
    setValues({ ...values, ...value });
  };

  useEffect(() => {
    fetchExpectedChallengingBehaviors(patientId);
  }, []);

  useEffect(() => {
    const updatedFilteredChallengingBehaviors =
      getFilteredChallengingBehaviors();
    setFilteredChallengingBehaviors(updatedFilteredChallengingBehaviors);
  }, [
    showOnlyExpectedChallengingBehaviors,
    challengingBehaviors,
    expectedChallengingBehaviorList,
  ]);

  const classes = useStyles();

  const intensityMarks = [
    {
      value: 1,
      label: "Molto Bassa",
    },
    {
      value: 2,
      label: "Bassa",
    },
    {
      value: 3,
      label: "Media",
    },
    {
      value: 4,
      label: "Alta",
    },
    {
      value: 5,
      label: "Molto Alta",
    },
  ];

  const patientId = values.patient || "";

  const getFilteredChallengingBehaviors = () => {
    if (showOnlyExpectedChallengingBehaviors) {
      return challengingBehaviors.filter((cb) =>
        expectedChallengingBehaviorList.some((ecb) => cb.id === ecb.id)
      );
    }
    return challengingBehaviors;
  };

  const fetchExpectedChallengingBehaviors = (id) => {
    ExpectedPatientChallengingBehaviorService.all(id).then((data) => {
      setExpectedChallengingBehaviorList(data);
    });
  };

  const handlePatientChange = (e) => {
    const { value } = e.target;
    setValue({
      patient: parseInt(value),
    });
    fetchExpectedChallengingBehaviors(value);
    checkChallengingBehaviorAvailableOptions();
  };

  const toggleShowOnlyExpectedChallengingBehaviors = () => {
    setShowOnlyExpectedChallengingBehaviors(
      !showOnlyExpectedChallengingBehaviors
    );
    checkChallengingBehaviorAvailableOptions();
  };

  // check if current challenging behavior selection is coherent with patient filter
  const checkChallengingBehaviorAvailableOptions = () => {
    if (showOnlyExpectedChallengingBehaviors) {
      const selectedOptions = filteredChallengingBehaviors.some(
        (cb) => cb.id === patientId
      );
      if (!selectedOptions) {
        setValue({
          challenging_behavior: {
            id: 0,
            title: "",
          },
        });
      }
    }
  };

  return (
    <>
      <form autoComplete="off">
        <TextField
          id="patient"
          select
          fullWidth
          variant="outlined"
          label="Utente"
          placeholder="Seleziona un utente"
          margin="normal"
          value={patientId}
          required
          onChange={handlePatientChange}
        >
          {patientList.map((option) => {
            const selected = values.patient === option.id;
            return (
              <MenuItem key={option.id} value={option.id} selected={selected}>
                {`${option.first_name} ${option.last_name}`}
              </MenuItem>
            );
          })}
        </TextField>

        <Autocomplete
          id="challenging_behavior"
          options={filteredChallengingBehaviors}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.id === value.id}
          value={values.challenging_behavior || ""}
          onChange={(e, value) => {
            setValue({
              challenging_behavior: value,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              label="Comportamento Problema"
              placeholder="Seleziona un Comportamento Problema"
              margin="normal"
              required
            />
          )}
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyExpectedChallengingBehaviors}
                onChange={toggleShowOnlyExpectedChallengingBehaviors}
              />
            }
            label="Mostra solo i C.P. associati all'utente selezionato"
          />
        </FormGroup>

        <TextField
          id="user"
          select
          fullWidth
          variant="outlined"
          label="Operatore"
          placeholder="Seleziona un operatore"
          margin="normal"
          required
          value={values.user || ""}
          onChange={(e) => {
            setValue({ user: parseInt(e.target.value) });
          }}
        >
          {userList.map((option) => {
            const selected = values.user === option.id;
            return (
              <MenuItem key={option.id} value={option.id} selected={selected}>
                {`${option.first_name} ${option.last_name}`}
              </MenuItem>
            );
          })}
        </TextField>

        <DateTimePicker
          locale={itLocale}
          fullWidth
          inputVariant="outlined"
          ampm={false}
          format={dateTimeFormat}
          margin="normal"
          label="Data e Ora inizio"
          value={values.start_date}
          onChange={(value) => {
            setValue({
              start_date: value,
            });
          }}
        />

        <DateTimePicker
          locale={itLocale}
          fullWidth
          inputVariant="outlined"
          ampm={false}
          format={dateTimeFormat}
          margin="normal"
          label="Data e Ora fine"
          value={values.end_date}
          onChange={(value) => {
            setValue({
              end_date: value,
            });
          }}
        />

        <Typography gutterBottom>Intensità</Typography>
        <div className={classes.intensityField}>
          <Slider
            min={1}
            max={5}
            step={1}
            aria-labelledby="intensity"
            valueLabelDisplay="auto"
            marks={intensityMarks}
            value={values.intensity}
            onChange={(event, value) => {
              setValue({
                intensity: value,
              });
            }}
          />
        </div>

        <TextField
          id="notes"
          label="Note"
          multiline
          margin="normal"
          fullWidth
          rows={4}
          variant="outlined"
          value={values.notes}
          onChange={(event) => {
            setValue({
              notes: event.target.value,
            });
          }}
        />

        {children}
      </form>
    </>
  );
}

Form.propTypes = propTypes;

export default Form;
