import React from 'react';
import {
   TextField,
   ListItem,
   ListItemAvatar,
   Avatar,
   ListItemIcon,
   IconButton
} from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import theme from '../theme';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

export default function TaskItem({ task, index, setTask, removeTask }) {
   const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: '10px',
      margin: `0 0 20px 0`,
      width: '100%',

      // change background colour if dragging
      background: isDragging ? theme.palette.success.dark : 'transparent',

      // styles we need to apply on draggables
      ...draggableStyle
   });

   return (
      <Draggable key={index} draggableId={'' + index} index={index}>
         {(provided, snapshot) => (
            <ListItem
               ref={provided.innerRef}
               {...provided.draggableProps}
               {...provided.dragHandleProps}
               style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
               )}
            >
               <ListItemIcon>
                  <DragIndicatorIcon />
               </ListItemIcon>
               <ListItemAvatar>
                  <Avatar>{index + 1}</Avatar>
               </ListItemAvatar>

               <TextField
                  required
                  value={task.title}
                  onChange={e => {
                     setTask(index, {
                        id: task.id,
                        title: e.target.value
                     });
                  }}
                  fullWidth
               />
               <IconButton
                  edge="end"
                  aria-label="elimina"
                  onClick={() => {
                     removeTask(index);
                  }}
               >
                  <DeleteIcon />
               </IconButton>
            </ListItem>
         )}
      </Draggable>
   );
}
