import axios from "axios";

const endpointUrl = "/api/goal-results";

export default class GoalResultService {
  static async getByPatient(patientId, params = {}) {
    const url = `/api/patients/${patientId}/goal-results/`;
    params.goal = params.goal || null;
    params.month = params.month || null;
    params.year = params.year || null;
    params.value =
      !params.value || params.value === "all" ? null : params.value;
    const response = await axios.get(url, {
      params,
    });
    return response.data;
  }

  static async delete(id) {
    const url = `${endpointUrl}/${id}/`;
    return await axios.delete(url);
  }

  static async save(data) {
    const url = `${endpointUrl}/`;
    return await axios.post(url, data);
  }

  static async getStatistics(patientId, params = {}) {
    return this._getStatistics(patientId, params);
  }

  static async getOverallStatistics(patientId, params = {}) {
    return this._getStatistics(patientId, params, true);
  }

  static async _getStatistics(patientId, params = {}, overall = false) {
    const statisticUrl = overall ? "overall-statistics" : "statistics";
    const url = `/api/patients/${patientId}/goal-results/${statisticUrl}`;
    params.month = params.month || null;
    params.year = params.year || null;
    const response = await axios.get(url, {
      params,
    });
    return response.data;
  }
}
