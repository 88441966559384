import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTimePicker } from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Alert from "@material-ui/lab/Alert";

import AppContext from "../AppContext";
import { dateTimeFormat } from "../utilities/date";

const propTypes = {
  values: PropTypes.shape({
    patient: PropTypes.number,
    user: PropTypes.number,
    start_time: PropTypes.object,
    end_time: PropTypes.object,
    note: PropTypes.string,
  }).isRequired,
  setValues: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
}));

function Form({ values, setValues, goals, children }) {
  const { patientList, userList } = useContext(AppContext);
  const setValue = (value) => {
    setValues({ ...values, ...value });
  };

  const patientId = values.patient || "";

  const handlePatientChange = (event) => {
    const { value } = event.target;
    setValue({ patient: value });
  };

  const classes = useStyles();

  return (
    <>
      <form autoComplete="off">
        <TextField
          id="patient"
          select
          fullWidth
          variant="outlined"
          label="Utente"
          placeholder="Seleziona un utente"
          margin="normal"
          value={patientId}
          onChange={handlePatientChange}
          required
        >
          {patientList.map((option) => {
            const selected = values.patient === option.id;
            return (
              <MenuItem key={option.id} value={option.id} selected={selected}>
                {`${option.first_name} ${option.last_name}`}
              </MenuItem>
            );
          })}
        </TextField>
        

        <TextField
          id="user"
          select
          fullWidth
          variant="outlined"
          label="Operatore"
          placeholder="Seleziona un operatore"
          margin="normal"
          required
          value={values.user || ""}
          onChange={(e) => {
            setValue({ user: parseInt(e.target.value) });
          }}
        >
          {userList.map((option) => {
            const selected = values.user === option.id;
            return (
              <MenuItem key={option.id} value={option.id} selected={selected}>
                {`${option.first_name} ${option.last_name}`}
              </MenuItem>
            );
          })}
        </TextField>

        <DateTimePicker
          locale={itLocale}
          fullWidth
          inputVariant="outlined"
          ampm={false}
          format={dateTimeFormat}
          margin="normal"
          label="Data e Ora Inizio"
          value={values.start_time}
          onChange={(value) => {
            setValue({
              start_time: value,
            });
          }}
        />

        <DateTimePicker
          locale={itLocale}
          fullWidth
          inputVariant="outlined"
          ampm={false}
          format={dateTimeFormat}
          margin="normal"
          label="Data e Ora Fine"
          value={values.end_time}
          onChange={(value) => {
            setValue({
              end_time: value,
            });
          }}
        />

        <TextField
          id="note"
          label="Note"
          multiline
          margin="normal"
          fullWidth
          rows={4}
          variant="outlined"
          value={values.note}
          onChange={(event) => {
            setValue({
              note: event.target.value,
            });
          }}
        />
        {children}
      </form>
    </>
  );
}

Form.propTypes = propTypes;

export default Form;
