import axios from "axios";

const endpointUrl = "/api/handovers";

export default class HandoverService {
  static async all(params = {}) {
    const url = `${endpointUrl}/`;
    const response = await axios.get(url, { params });
    return response.data;
  }

  static async get(id) {
    const url = `${endpointUrl}/${id}/`;
    const response = await axios.get(url);
    return response.data;
  }

  static async getByPatient(patientId) {
    const url = `/api/patients/${patientId}/handover/`;
    const response = await axios.get(url, {
      patient: patientId,
    });
    return response.data;
  }

  static async delete(id) {
    const url = `${endpointUrl}/${id}/`;
    return await axios.delete(url);
  }

  static async save(data) {
    const url = `${endpointUrl}/`;
    return await axios.post(url, data);
  }

  static async update(id, data) {
    const url = `${endpointUrl}/${id}/`;
    return await axios.put(url, data);
  }
}
