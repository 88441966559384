import axios from 'axios';

export default class PatientService {
   static async all() {
      const url = `/api/patients/`;
      const response = await axios.get(url);
      return response.data;
   }

   static async get(pk) {
      const url = `/api/patients/${pk}/`;
      const response = await axios.get(url);
      return response.data;
   }
}
