import React, { useEffect, useState, useRef } from "react";
import { LinearProgress, Typography, Box } from "@material-ui/core";

import GoalResultService from "../services/GoalResultService";
import GoalService from "../services/GoalService";
import GoalResultFilter from "../GoalResultFilter/GoalResultFilter";
import LinearChart from "./LinearChart";
import PieChart from "./PieChart";
import ResultBarChart from "../ResultBarChart/ResultBarChart";
import { localizeLabel } from "../utilities/locale";
import {
  getCurrentMonth,
  getCurrentYear,
  getYearOptions,
  getMonthOptions,
} from "../utilities/date";
import GoalResultStatisticsPDF from "./GoalResultStatisticsPDF";

const currentMonth = getCurrentMonth();
const currentYear = getCurrentYear();

const monthOptions = getMonthOptions({ addAllOption: true });
const availableYears = getYearOptions({ addAllOption: true });

function GoalResultStatistics(props) {
  const { match } = props;
  const { patientId } = match.params;

  const [data, setData] = useState();
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [goal, setGoal] = useState("");
  const [availableGoals, setAvailableGoals] = useState([]);
  const [graphsAreReady, setGraphsAreReady] = useState(false);
  const lineChartRef = useRef();
  const barChartRef = useRef();
  const pieChartRef = useRef();

  useEffect(() => {
    GoalService.getByPatient(patientId).then((data) => {
      setAvailableGoals(data);
      if (data.length) setGoal(data[0].id);
    });
  }, [patientId]);

  useEffect(() => {
    if (goal) {
      const monthValue = month === "all" ? null : month;
      const yearValue = year === "all" ? null : year;
      setGraphsAreReady(false);
      GoalResultService.getStatistics(patientId, {
        goal,
        month: monthValue,
        year: yearValue,
        //  fromDate: '2020-01-01',
        //  toDate: '2020-02-28',
      }).then((data) => {
        setData(data);
      });
    }
  }, [month, year, goal, patientId]);

  if (!availableGoals) return <LinearProgress />;

  const currentMonthLabel = monthOptions.find(
    (option) => option.value === month
  ).label;
  const currentYearLabel = availableYears.find(
    (option) => option.value === year
  ).label;

  const activeFilters = {
    goal: goal
      ? availableGoals.find((option) => option.id === goal).title
      : null,
    month: {
      value: month,
      label: currentMonthLabel,
    },
    year: {
      value: year,
      label: currentYearLabel,
    },
  };

  const barChartData = data
    ? data.total.reduce(
        (acc, result) => {
          const label = localizeLabel(result.value);
          acc[label] = result.count;
          return acc;
        },
        {
          name: currentMonthLabel,
        }
      )
    : null;

  const pieChartData = data
    ? data.total.map((result) => {
        const label = localizeLabel(result.value);
        return {
          name: label,
          value: result.count,
          key: result.value,
        };
      })
    : null;

  const valueToScore = (value) => {
    switch (value) {
      case "failed":
        return 1;
      case "emergent":
        return 2;
      case "succeeded":
        return 3;
    }
    return 0;
  };

  const lineChartDataObj = data
    ? data.daily.reduce((days, result) => {
        const { value } = result;
        // if not classifiable continue
        if (value === "not_classifiable") return days;
        // take only the day number
        const date = result.day.split("-");

        const name = date[2] + "/" + date[1];
        const score = valueToScore(value);

        days[result.day] = {
          [localizeLabel("result")]: score,
          name,
          value,
        };

        return days;
      }, {})
    : null;

  let lineChartData = lineChartDataObj
    ? Object.keys(lineChartDataObj).map((day) => lineChartDataObj[day])
    : null;

  const onAnimationsEnd = () => {
    setGraphsAreReady(true);
  };

  return (
    <>
      <Typography variant="h6">Statistiche obiettivi</Typography>
      <GoalResultFilter
        availableGoals={availableGoals}
        availableMonths={monthOptions}
        month={month}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
        goal={goal}
        setGoal={setGoal}
        availableYears={availableYears}
      />

      <Box textAlign="right">
        <GoalResultStatisticsPDF
          match={match}
          lineChartRef={lineChartRef}
          barChartRef={barChartRef}
          pieChartRef={pieChartRef}
          disabled={!graphsAreReady}
          activeFilters={activeFilters}
        />
      </Box>

      <Box mb={5}>
        <LinearChart
          lineChartRef={lineChartRef}
          data={lineChartData}
          onAnimationsEnd={onAnimationsEnd}
        />
        <Typography>Andamento degli esiti giornalieri</Typography>
      </Box>

      <Box mb={5}>
        <ResultBarChart data={barChartData} barChartRef={barChartRef} />
        <Typography paragraph>
          Sommatoria degli esiti nell'intervallo
        </Typography>
      </Box>

      <Box mb={5}>
        <PieChart
          data={pieChartData}
          pieChartRef={pieChartRef}
          onAnimationsEnd={onAnimationsEnd}
        />
        <Typography paragraph>
          Percentuale degli esiti nell'intervallo
        </Typography>
      </Box>
    </>
  );
}

export default GoalResultStatistics;
