import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerContext from '../AppDrawer/DrawerContext';
import AppContext from '../AppContext';
import logo from '../logo.svg';

const useStyles = makeStyles({
   logo: {
      marginLeft: 'auto',
      marginRight: 'auto'
   }
});

function NavBar() {
   const classes = useStyles();
   const drawerContext = useContext(DrawerContext);
   const appContext = useContext(AppContext);
   const onDrawerButtonClick = () => {
      drawerContext.setShowDrawer(!drawerContext.showDrawer);
   };
   return (
      <AppBar position="fixed">
         <Toolbar>
            {appContext.loggedUser && (
               <IconButton
                  onClick={onDrawerButtonClick}
                  edge="start"
                  color="inherit"
                  aria-label="menu"
               >
                  <MenuIcon />
               </IconButton>
            )}
            <Typography variant="h6" className={classes.logo}>
               <img src={logo} height={38} alt="logo" />
            </Typography>
         </Toolbar>
      </AppBar>
   );
}

export default NavBar;
