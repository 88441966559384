import axios from "axios";

const endpointUrl = "/api/goals";

export default class GoalService {
  static async getByPatient(patientId) {
    const url = `/api/patients/${patientId}/goals/`;
    const response = await axios.get(url, {
      patient: patientId,
    });
    return response.data;
  }

  static async delete(id) {
    const url = `${endpointUrl}/${id}/`;
    return await axios.delete(url);
  }

  static async save(data) {
    const url = `${endpointUrl}/`;
    return await axios.post(url, data);
  }
}
