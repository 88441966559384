import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, LinearProgress, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { addMinutes, format } from "date-fns";

import { serverDateTimeFormat } from "../utilities/date";
import AppContext from "../AppContext";
import ChallengingBehaviorService from "../services/ChallengingBehaviorService";
import ChallengingBehaviorRegistrationService from "../services/ChallengingBehaviorRegistrationService";
import Form from "./Form";

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

function ChallengingBehaviorRegistration({ location }) {
  const params = queryString.parse(location.search);
  const { patientList, loggedUser } = useContext(AppContext);
  const defaultValues = {
    start_date: new Date(),
    end_date: addMinutes(new Date(), 1),
    intensity: 3,
    challenging_behavior: null,
  };

  const [challengingBehaviors, setChallengingBehaviors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  const [values, setValues] = useState(defaultValues);
  const history = useHistory();

  useEffect(() => {
    ChallengingBehaviorService.all().then((response) => {
      setChallengingBehaviors(response);

      defaultValues.user = params.user
        ? parseInt(params.user)
        : loggedUser.user_id;
      defaultValues.patient = params.patient
        ? parseInt(params.patient)
        : patientList[0].id;
      defaultValues.challenging_behavior = params.challenging_behavior
        ? parseInt(params.challenging_behavior)
        : response[0];

      setIsLoading(false);
    });
  }, [params.challenging_behavior, params.user, params.patient]);

  if (isLoading) return <LinearProgress />;

  const handleSubmit = () => {
    ChallengingBehaviorRegistrationService.save({
      challenging_behavior_id: values.challenging_behavior.id,
      start_time: format(values.start_date, serverDateTimeFormat),
      end_time: format(values.end_date, serverDateTimeFormat),
      patient: values.patient,
      user_id: values.user,
      intensity: values.intensity,
      notes: values.notes,
    })
      .then(() => {
        history.push(`/app/patients/${values.patient}/challenging-behaviors`);
      })
      .catch((error) => {
        // TODO: add error with snackbar (ex. ChecklistEdit.jsx)
        console.error("Error saving challenging behavior registration", error);
      });
  };

  return (
    <>
      <Typography variant="h6">Registra un Comportamento Problema</Typography>
      <Form
        values={values}
        setValues={setValues}
        challengingBehaviors={challengingBehaviors}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.submitBtn}
          onClick={handleSubmit}
        >
          Registra
        </Button>
      </Form>
    </>
  );
}

export default ChallengingBehaviorRegistration;
