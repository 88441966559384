import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  LinearProgress,
  ListItemAvatar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Popover,
  Box,
  IconButton,
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Alert from "@material-ui/lab/Alert";
import FlagIcon from "@material-ui/icons/Flag";
import DeleteIcon from "@material-ui/icons/Delete";
import NotesIcon from "@material-ui/icons/Notes";
import { makeStyles } from "@material-ui/core/styles";
import { green, yellow, red, grey } from "@material-ui/core/colors";

import GoalResultService from "../services/GoalResultService";
import GoalService from "../services/GoalService";
import {
  formattedDate,
  formattedDistance,
  getCurrentMonth,
  getCurrentYear,
  getYearOptions,
  getMonthOptions,
} from "../utilities/date";
import GoalResultOverallStatistics from "../GoalResultOverallStatistics/GoalResultOverallStatistics";
import GoalResultFilter from "../GoalResultFilter/GoalResultFilter";

const useStyles = makeStyles((theme) => ({
  green: {
    color: green[500],
  },
  yellow: {
    color: yellow[700],
  },
  red: {
    color: red[700],
  },
  grey: {
    color: grey[500],
  },
  textRight: {
    textAlign: "right",
  },
  inline: {
    display: "inline",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

function getResultColorClass(intensity) {
  switch (intensity) {
    case "succeeded":
      return "green";
    case "emergent":
      return "yellow";
    case "failed":
      return "red";
    case "not_classifiable":
      return "grey";
  }
}

const resultKeyToLabel = {
  all: "Tutti",
  failed: "Fallito",
  emergent: "Emergente",
  succeeded: "Riuscito",
  not_classifiable: "Non classificabile",
};

const currentMonth = getCurrentMonth();
const currentYear = getCurrentYear();

const monthOptions = getMonthOptions({ addAllOption: true });
const availableYears = getYearOptions({ addAllOption: true });
const resultTypeOptions = Object.keys(resultKeyToLabel).map((key) => ({
  value: key,
  label: resultKeyToLabel[key],
}));

function GoalResultList(props) {
  const { match } = props;
  const { patientId } = match.params;

  const classes = useStyles();

  const [results, setResults] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [resultToDelete, setResultToDelete] = useState(null);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [goal, setGoal] = useState(0);
  const [resultType, setResultType] = useState("all");
  const [availableGoals, setAvailableGoals] = useState([
    {
      id: 0,
      title: "Tutti gli obiettivi",
    },
  ]);

  useEffect(() => {
    GoalService.getByPatient(patientId).then((data) => {
      const goals = [
        {
          id: 0,
          title: "Tutti gli obiettivi",
        },
        ...data,
      ];

      setAvailableGoals(goals);
      setGoal(goals[0].id);
    });
  }, [patientId]);

  useEffect(() => {
    const monthValue = month === "all" ? null : month;
    const yearValue = year === "all" ? null : year;
    GoalResultService.getByPatient(patientId, {
      goal,
      month: monthValue,
      year: yearValue,
      value: resultType,
    }).then((data) => {
      setResults(data);
    });
  }, [month, year, goal, resultType, patientId]);

  const openDeleteDialog = (id) => {
    setResultToDelete(id);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => {
    setResultToDelete(null);
    setShowDeleteDialog(false);
  };

  const handleDeleteRegistration = () => {
    GoalResultService.delete(resultToDelete)
      .then(() => {
        closeDeleteDialog();
        removeGoalResultFromList(resultToDelete);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeGoalResultFromList = (id) => {
    const resultIndex = results.findIndex((r) => r.id === id);
    let newList = [...results];
    newList.splice(resultIndex, 1);
    setResults(newList);
  };

  return (
    <>
      <Typography variant="h6">Panoramica obiettivi</Typography>
      <GoalResultOverallStatistics patientId={patientId} />
      <Divider className={classes.divider} />
      <Typography variant="h6">Esiti obiettivi</Typography>
      <GoalResultFilter
        availableGoals={availableGoals}
        availableMonths={monthOptions}
        month={month}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
        goal={goal}
        setGoal={setGoal}
        availableYears={availableYears}
        availableResultTypes={resultTypeOptions}
        resultType={resultType}
        setResultType={setResultType}
      />
      {!results && <LinearProgress />}
      {results && results.length === 0 && (
        <Alert severity="warning">
          Nessun esito registrato su questo utente
        </Alert>
      )}
      {results && results.length > 0 && (
        <List>
          {results.map((result) => {
            const { goal, registration_time, patient } = result;
            const registrationTimeStr = `${formattedDistance(
              registration_time
            )} (${formattedDate(
              registration_time,
              "EEEEEE dd/MM/yyyy HH:mm:ss"
            )})`;

            return (
              <span key={result.id}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <FlagIcon
                      className={classes[getResultColorClass(result.value)]}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={result.goal.title}
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {registrationTimeStr}
                        </Typography>
                      </>
                    }
                  />
                  <div className={classes.textRight}>
                    <div>{resultKeyToLabel[result.value]}</div>
                    <div>
                      {result.note && (
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <span>
                              <IconButton
                                edge="end"
                                aria-label="Note"
                                {...bindTrigger(popupState)}
                              >
                                <NotesIcon />
                              </IconButton>
                              <Popover {...bindPopover(popupState)}>
                                <Box p={2}>
                                  <Typography>{result.note}</Typography>
                                </Box>
                              </Popover>
                            </span>
                          )}
                        </PopupState>
                      )}
                      <IconButton
                        edge="end"
                        aria-label="Elimina"
                        onClick={() => {
                          openDeleteDialog(result.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </ListItem>
                <Divider variant="inset" component="li" />
              </span>
            );
          })}
        </List>
      )}
      <Dialog open={showDeleteDialog} onClose={closeDeleteDialog}>
        <DialogTitle>Sei sicuro di voler cancellare questo esito?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Una volta eliminato non sarà più possibile recuperarlo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteRegistration} color="primary">
            Conferma
          </Button>
          <Button onClick={closeDeleteDialog} color="primary" autoFocus>
            Annulla
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GoalResultList;
