import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { localizeLabel } from "../utilities/locale";

export default function ResultBarChart({ data, barChartRef }) {
  return (
    <ResponsiveContainer width="100%" aspect={1.5} ref={barChartRef}>
      <BarChart
        margin={{ top: 20, right: 10, left: 0, bottom: 20 }}
        data={[data]}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={localizeLabel("succeeded")} fill="#00C49F" />
        <Bar dataKey={localizeLabel("emergent")} fill="#FFBB28" />
        <Bar dataKey={localizeLabel("failed")} fill="#FF8042" />
        <Bar dataKey={localizeLabel("not_classifiable")} fill="#b3b3b3" />
      </BarChart>
    </ResponsiveContainer>
  );
}
