import React from "react";
import PropTypes from "prop-types";
import { LinearProgress, TextField, MenuItem, Grid } from "@material-ui/core";

import { availableMonthOptions } from "../utilities/date";

const propTypes = {
  availableChecklists: PropTypes.array,
  month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setMonth: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setYear: PropTypes.func.isRequired,
  checklist: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setChecklist: PropTypes.func.isRequired,
  availableYears: PropTypes.array,
};

const defaultProps = {
  availableChecklists: [],
  availableYears: [],
  availableMonths: availableMonthOptions,
};

function ChecklistExecutionFilter({
  availableChecklists,
  month,
  setMonth,
  year,
  setYear,
  checklist,
  setChecklist,
  availableYears,
  availableMonths,
}) {
  if (!availableChecklists) return <LinearProgress />;

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleChecklistChange = (e) => {
    setChecklist(e.target.value);
  };

  return (
    <>
      <form autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              id="month"
              select
              fullWidth
              label="Mese"
              margin="normal"
              value={month}
              onChange={handleMonthChange}
            >
              {availableMonths.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="year"
              select
              fullWidth
              label="Anno"
              margin="normal"
              value={year}
              onChange={handleYearChange}
            >
              {availableYears.map((year) => (
                <MenuItem value={year.value} key={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <TextField
          id="checklist"
          select
          fullWidth
          label="Prova"
          margin="normal"
          value={checklist}
          onChange={handleChecklistChange}
        >
          {availableChecklists.map(({ id, title }) => (
            <MenuItem value={id} key={id}>
              {title}
            </MenuItem>
          ))}
        </TextField>
      </form>
    </>
  );
}

ChecklistExecutionFilter.propTypes = propTypes;
ChecklistExecutionFilter.defaultProps = defaultProps;

export default ChecklistExecutionFilter;
