import axios from "axios";

const endpointUrl = "/api/challenging-behaviors";

export default class ChallengingBehaviorService {
  static async all() {
    const url = `${endpointUrl}/`;
    const response = await axios.get(url);
    return response.data;
  }

  static async get(pk) {
    const url = `${endpointUrl}/${pk}/`;
    const response = await axios.get(url);
    return response.data;
  }

  static async delete(id) {
    const url = `${endpointUrl}/${id}/`;
    return await axios.delete(url);
  }

  static async save(data) {
    const url = `${endpointUrl}/`;
    return await axios.post(url, data);
  }
}
