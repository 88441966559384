import React from "react";
import PropTypes from "prop-types";
import { LinearProgress, TextField, MenuItem, Grid } from "@material-ui/core";

import { availableMonthOptions } from "../utilities/date";

const propTypes = {
  availableGoals: PropTypes.array,
  month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setMonth: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setYear: PropTypes.func.isRequired,
  goal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setGoal: PropTypes.func.isRequired,
  availableYears: PropTypes.array,
  availableResultTypes: PropTypes.array,
  resultType: PropTypes.string,
  setResultType: PropTypes.func,
};

const defaultProps = {
  availableGoals: [],
  availableYears: [],
  availableMonths: availableMonthOptions,
  availableResultTypes: null,
};

function GoalResultFilter({
  availableGoals,
  month,
  setMonth,
  year,
  setYear,
  goal,
  setGoal,
  availableYears,
  availableMonths,
  availableResultTypes,
  resultType,
  setResultType,
}) {
  if (!availableGoals) return <LinearProgress />;

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleGoalChange = (e) => {
    setGoal(e.target.value);
  };

  const handleResultTypeChange = (e) => {
    setResultType(e.target.value);
  };

  return (
    <>
      <form autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              id="month"
              select
              fullWidth
              label="Mese"
              margin="normal"
              value={month}
              onChange={handleMonthChange}
            >
              {availableMonths.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="year"
              select
              fullWidth
              label="Anno"
              margin="normal"
              value={year}
              onChange={handleYearChange}
            >
              {availableYears.map((year) => (
                <MenuItem value={year.value} key={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={availableResultTypes ? 6 : 12}>
            <TextField
              id="goal"
              select
              fullWidth
              label="Obiettivo"
              margin="normal"
              value={goal}
              onChange={handleGoalChange}
            >
              {availableGoals.map(({ id, title }) => (
                <MenuItem value={id} key={id}>
                  {title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {availableResultTypes && (
            <Grid item xs={6}>
              <TextField
                id="resultType"
                select
                fullWidth
                label="Esito"
                margin="normal"
                value={resultType}
                onChange={handleResultTypeChange}
              >
                {availableResultTypes.map(({ value, label }) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
}

GoalResultFilter.propTypes = propTypes;
GoalResultFilter.defaultProps = defaultProps;

export default GoalResultFilter;
