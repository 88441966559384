import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import AppContext from "../AppContext";

function PrivateRoute({ component: Component, ...rest }) {
  const appContext = useContext(AppContext);
  const { loggedUser } = appContext;

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/app/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
