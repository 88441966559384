import axios from "axios";

const endpointUrl = "/api/challenging-behavior-registrations";

export default class ChallengingBehaviorRegistrationService {
  static async all() {
    const url = `${endpointUrl}/`;
    const response = await axios.get(url);
    return response.data;
  }

  static async get(pk) {
    const url = `${endpointUrl}/${pk}/`;
    const response = await axios.get(url);
    return response.data;
  }

  static async delete(id) {
    const url = `${endpointUrl}/${id}/`;
    return await axios.delete(url);
  }

  static async save(data) {
    const url = `${endpointUrl}/`;
    return await axios.post(url, data);
  }

  static async getByFilter(params) {
    const url = `/challenging-behavior-registration-list/`;
    const response = await axios.get(url, {
      params,
    });
    return response.data;
  }

  static async getStatistics(params) {
    const url = `challenging-behavior-statistics/`;
    const response = await axios.get(url, {
      params,
    });
    return response.data;
  }

  static async getPatientChallengingBehaviors(params) {
    const url = `patient-challenging-behaviors/`;
    const response = await axios.get(url, {
      params,
    });
    return response.data;
  }
}
