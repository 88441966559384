import axios from 'axios';

export default class UserService {
   static async all() {
      const url = `/api/users/`;
      const response = await axios.get(url);
      return response.data;
   }

   static async get(pk) {
      const url = `/api/users/${pk}`;
      const response = await axios.get(url);
      return response.data;
   }

   /**
    * TODO get more info from user declairing CustomAuthToken class
    * https://www.django-rest-framework.org/api-guide/authentication/#tokenauthentication
    */
   static login(username, password) {
      const url = `/api-token-auth/`;
      const params = {
         username,
         password
      };
      axios.defaults.headers.common = {};
      return axios.post(url, params);
   }
}
