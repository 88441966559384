import React from 'react';
import { contrastTextFromRGB, stringToRGB } from '../utilities/colors';
import Avatar from '@material-ui/core/Avatar';

export default function PatientAvatar({ firstName, lastName, ...otherProps }) {
   const patientInitials = lastName[0] + firstName[0];
   const rgb = stringToRGB(`${firstName} ${lastName}`);
   const backgroundColor = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
   const textColor = contrastTextFromRGB(rgb);

   const avatarStyle = {
      backgroundColor,
      color: textColor
   };

   return (
      <Avatar style={avatarStyle} {...otherProps}>
         {patientInitials}
      </Avatar>
   );
}
