import React from "react";

function PageNotFound(props) {
  const { location } = props;
  return (
    <div className="text-center">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <h3>{location.pathname}</h3>
    </div>
  );
}

export default PageNotFound;
