import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { format } from "date-fns";
import { IconButton } from "@material-ui/core";
import logo from "../logo.svg";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { formattedDate } from "../utilities/date";

const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 45,
  },
  section: {
    // margin: 20,
    // padding: 30,
  },
  mainTitle: {
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
  },
  subTitle: {
    fontSize: 14,
    textAlign: "center",
    color: "grey",
    marginTop: 6,
    marginBottom: 20,
  },
  filterBoxTitle: {
    fontWeight: "bold",
    fontSize: 14,
    marginBottom: 6,
  },
  filterBox: {
    borderWidth: 1,
    borderRadius: 10,
    fontSize: 12,
    padding: 10,
    marginBottom: 16,
  },
  handoverHeader: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 6,
  },
  text: {
    fontSize: 10,
    textAlign: "justify",
  },
  handoverBox: {
    paddingBottom: 12,
    marginBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: "grey",
  },
  flexSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const createAndDownloadPDF = (pdfContent, filename, divId, callback) => {
  setTimeout(() => {
    const link = (
      <div id={divId}>
        <PDFDownloadLink
          document={pdfContent}
          fileName={filename}
        ></PDFDownloadLink>
      </div>
    );
    const elem = document.createElement("div");
    document.getElementById("root").appendChild(elem);
    ReactDOM.render(link, elem);
    setTimeout(() => {
      document.getElementById(divId).children[0].click();
      elem.remove();
      callback();
    }, 1);
  }, 1);
};

export default function HandoverListPDF({ activeFilters, handovers }) {
  const currentDateTime = format(new Date(), "dd-MM-yyyy HH:mm:ss");
  let fileName = `storico_consegne-${currentDateTime}.pdf`;
  fileName = fileName.replaceAll(" ", "_");

  const [loadingPDF, setLoadingPDF] = useState();

  const buildPDF = () => {
    if (!loadingPDF) {
      setLoadingPDF(true);

      const Report = () => (
        <Document
          author="skillseducation.app"
          title={`Storico Consegne`}
          subject="Storico Consegne"
        >
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text style={styles.mainTitle}>Storico Consegne</Text>
              <Text style={styles.subTitle}>{currentDateTime}</Text>
              <View style={styles.filterBox}>
                <Text style={styles.filterBoxTitle}>Filtri attivi</Text>
                <View style={styles.flexSpaceBetween}>
                  <Text>Mese: {activeFilters.month.label}</Text>
                  <Text>Anno: {activeFilters.year.label}</Text>
                  <Text>Operatore: {activeFilters.user?.label}</Text>
                  <Text>Utente: {activeFilters.patient?.label}</Text>
                </View>
              </View>

              {handovers && handovers.length > 0 && (
                <View>
                  {handovers.map((handover) => {
                    const { id, start_time, end_time, user, patient, note } =
                      handover;
                    const startDateStr = formattedDate(
                      start_time,
                      "EEEEEE dd/MM/yyyy"
                    );
                    const startTimeStr = formattedDate(start_time, "HH:mm:ss");
                    const endTimeStr = formattedDate(end_time, "HH:mm:ss");

                    return (
                      <View key={id} style={styles.handoverBox}>
                        <View style={styles.flexSpaceBetween}>
                          <Text style={styles.handoverHeader}>
                            Data: {startDateStr} da {startTimeStr} a{" "}
                            {endTimeStr}
                          </Text>
                        </View>
                        <View style={styles.flexSpaceBetween}>
                          <Text style={styles.handoverHeader}>
                            Utente: {patient.first_name} {patient.last_name}
                          </Text>
                          <Text style={styles.handoverHeader}>
                            Operatore: {user.first_name} {user.last_name}
                          </Text>
                        </View>
                        <Text style={styles.text}>{note}</Text>
                      </View>
                    );
                  })}
                </View>
              )}
            </View>
          </Page>
        </Document>
      );
      createAndDownloadPDF(<Report />, fileName, "pdf-creator-link", () =>
        setLoadingPDF(false)
      );
    }
  };

  return (
    <>
      <IconButton aria-label="Scarica PDF" onClick={buildPDF}>
        <PictureAsPdfIcon />
      </IconButton>
    </>
  );
}
