import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Typography } from "@material-ui/core";

import ChallengingBehaviorRegistrationService from "../services/ChallengingBehaviorRegistrationService";
import CustomBarChart from "./CustomBarChart";
import { weekDayFromNumberToString } from "../utilities/date";
import { green, red, yellow } from "../theme";

function ChallengingBehaviorStatistics(props) {
  const { match } = props;
  const { patientId } = match.params;
  const [challengingBehaviors, setChallengingBehaviors] = useState([]);
  const [challengingBehaviorFilter, setChallengingBehaviorFilter] = useState(0);
  const [hourData, setHourData] = useState([]);
  const [weekDayData, setWeekDayData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [yearData, setYearData] = useState([]);

  useEffect(() => {
    const params = {
      patient: patientId,
    };
    if (challengingBehaviorFilter) {
      params.challenging_behavior = challengingBehaviorFilter;
    }
    ChallengingBehaviorRegistrationService.getStatistics(params).then(
      (data) => {
        prepareData(data);
      }
    );
  }, [challengingBehaviorFilter]);

  useEffect(() => {
    const params = {
      patient: patientId,
    };
    ChallengingBehaviorRegistrationService.getPatientChallengingBehaviors(
      params
    ).then((data) => {
      setChallengingBehaviors(data);
    });
  }, []);

  function prepareData(data) {
    const { hours, week_days, months, years } = data;
    let hourDataArray = [];
    let weekDayArray = [];
    let monthArray = [];
    let yearArray = [];

    for (let key in hours) {
      hourDataArray.push({
        name: key,
        count: hours[key],
      });
    }
    hourDataArray = hourDataArray.sort((a, b) => {
      const aInt = parseInt(a.name);
      const bInt = parseInt(b.name);
      if (aInt > bInt) return 1;
      if (aInt < bInt) return -1;
      return 0;
    });

    for (let key in week_days) {
      weekDayArray.push({
        name: key,
        count: week_days[key],
      });
    }
    weekDayArray = weekDayArray.sort((a, b) => {
      let aInt = parseInt(a.name);
      let bInt = parseInt(b.name);
      // sunday at the end
      if (aInt === 0) return 1;
      if (aInt > bInt) return 1;
      if (aInt < bInt) return -1;
      return 0;
    });
    weekDayArray = weekDayArray.map((item) => {
      return {
        ...item,
        name: weekDayFromNumberToString(item.name, true),
      };
    });

    for (let key in months) {
      monthArray.push({
        name: key,
        count: months[key],
      });
    }

    for (let key in years) {
      yearArray.push({
        name: key,
        count: years[key],
      });
    }

    setHourData(hourDataArray);
    setWeekDayData(weekDayArray);
    setMonthData(monthArray);
    setYearData(yearArray);
  }

  const handleChallengingBehaviorsChange = (e) => {
    setChallengingBehaviorFilter(e.target.value);
  };

  return (
    <div>
      <Typography variant="h6">Statistiche Comportamenti Problema</Typography>
      <TextField
        id="challenging-behavior"
        select
        fullWidth
        label="Comportamento Problema"
        margin="normal"
        value={challengingBehaviorFilter}
        onChange={handleChallengingBehaviorsChange}
      >
        <MenuItem value={0} key={0}>
          Tutti
        </MenuItem>
        {challengingBehaviors.map(({ id, title }) => (
          <MenuItem value={id} key={id}>
            {title}
          </MenuItem>
        ))}
      </TextField>

      <h3>Ora</h3>
      <CustomBarChart data={hourData} />

      <h3>Giorno della settimana</h3>
      <CustomBarChart data={weekDayData} fill={yellow} />

      <h3>Mese</h3>
      <CustomBarChart data={monthData} fill={green} />

      <h3>Anno</h3>
      <CustomBarChart data={yearData} fill={red} />
    </div>
  );
}

export default ChallengingBehaviorStatistics;
