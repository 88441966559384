import React, { useEffect, useState, useRef } from "react";
import { LinearProgress, Typography, Box } from "@material-ui/core";

import ChecklistExecutionService from "../services/ChecklistExecutionService";
import ChecklistService from "../services/ChecklistService";
import ChecklistExecutionFilter from "../ChecklistExecutionFilter/ChecklistExecutionFilter";
import ResultLinearChart from "../ResultLinearChart/ResultLinearChart";
import ResultBarChart from "../ResultBarChart/ResultBarChart";
import { localizeLabel } from "../utilities/locale";
import {
  getCurrentMonth,
  getCurrentYear,
  getYearOptions,
  getMonthOptions,
} from "../utilities/date";
import ChecklistStatisticsPDF from "./ChecklistStatisticsPDF";

const currentYear = getCurrentYear();
const currentMonth = getCurrentMonth();
const availableYears = getYearOptions({ addAllOption: true });
const monthOptions = getMonthOptions({ addAllOption: true });

function ChecklistStatistics({ match }) {
  const { patientId } = match.params;

  const [data, setData] = useState();
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [checklist, setChecklist] = useState("");
  const [availableChecklists, setAvailableChecklists] = useState([]);
  const [graphsAreReady, setGraphsAreReady] = useState(false);
  const lineChartRef = useRef();
  const barChartRef = useRef();

  useEffect(() => {
    ChecklistService.all().then((data) => {
      setAvailableChecklists(data);
      setChecklist(data[0].id);
    });
  }, []);

  useEffect(() => {
    if (checklist) {
      const monthValue = month === "all" ? null : month;
      const yearValue = year === "all" ? null : year;
      setGraphsAreReady(false);
      ChecklistExecutionService.getStatistics({
        patient: patientId,
        checklist,
        month: monthValue,
        year: yearValue,
        //  fromDate: '2020-01-01',
        //  toDate: '2020-02-28',
      }).then((data) => {
        setData(data);
      });
    }
  }, [month, year, checklist, patientId]);

  if (!availableChecklists) return <LinearProgress />;

  const currentMonthLabel = monthOptions.find(
    (option) => option.value === month
  ).label;
  const currentYearLabel = availableYears.find(
    (option) => option.value === year
  ).label;

  const activeFilters = {
    checklist: checklist
      ? availableChecklists.find((option) => option.id === checklist).title
      : null,
    month: {
      value: month,
      label: currentMonthLabel,
    },
    year: {
      value: year,
      label: currentYearLabel,
    },
  };

  const barChartData = data
    ? data.total.reduce(
        (acc, result) => {
          const label = localizeLabel(result.value);
          acc[label] = result.count;
          return acc;
        },
        {
          name: currentMonthLabel,
        }
      )
    : null;

  const dailyExecutions = data
    ? data.daily_executions.reduce((acc, item) => {
        acc[item.day] = item.count;
        return acc;
      }, {})
    : null;

  const lineChartDataObj = data
    ? data.daily.reduce((days, result) => {
        // take only the day number
        const date = result.day.split("-");
        const dailyExecutionCount = dailyExecutions[result.day];
        const name = date[2] + "/" + date[1];

        const { value, count } = result;
        if (!days.hasOwnProperty(result.day)) {
          days[result.day] = {
            [localizeLabel("failed")]: 0,
            [localizeLabel("emergent")]: 0,
            [localizeLabel("succeeded")]: 0,
            name,
          };
        }
        days[result.day][localizeLabel(value)] = Math.round(
          count / dailyExecutionCount
        );

        return days;
      }, {})
    : null;

  const lineChartData = lineChartDataObj
    ? Object.keys(lineChartDataObj).map((day) => lineChartDataObj[day])
    : null;

  const onAnimationsEnd = () => {
    setGraphsAreReady(true);
  };

  return (
    <>
      <Typography variant="h6">Statistiche Prove</Typography>
      <ChecklistExecutionFilter
        availableChecklists={availableChecklists}
        availableMonths={monthOptions}
        month={month}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
        checklist={checklist}
        setChecklist={setChecklist}
        availableYears={availableYears}
      />

      <Box textAlign="right">
        <ChecklistStatisticsPDF
          match={match}
          lineChartRef={lineChartRef}
          barChartRef={barChartRef}
          disabled={!graphsAreReady}
          activeFilters={activeFilters}
        />
      </Box>

      <Box mb={5}>
        <ResultLinearChart
          lineChartRef={lineChartRef}
          data={lineChartData}
          onAnimationsEnd={onAnimationsEnd}
        />
        <Typography>
          Andamento dei risultati basato sulla media giornaliera
        </Typography>
      </Box>

      <ResultBarChart data={barChartData} barChartRef={barChartRef} />
      <Typography paragraph>
        Sommatoria dei risultati nell'intervallo
      </Typography>
    </>
  );
}

export default ChecklistStatistics;
