export function localizeLabel(label) {
  const map = {
    succeeded: "Riuscito",
    failed: "Fallito",
    emergent: "Emergente",
    not_classifiable: "Non Classificabile",
    result: "Esito",
  };
  if (map.hasOwnProperty(label)) return map[label];
  return "Non definito";
}
