import axios from "axios";

const endpointUrl = "/api/expected-patient-challenging-behaviors";

export default class ExpectedPatientChallengingBehaviorService {
  static async all(patient_id = null) {
    let url = `${endpointUrl}/`;
    if (patient_id) {
      url += `?patient=${patient_id}`;
    }
    const response = await axios.get(url);
    return response.data;
  }
}
