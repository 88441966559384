import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, LinearProgress } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";

import AppDrawer from "./AppDrawer/AppDrawer";
import DrawerContext from "./AppDrawer/DrawerContext";
import NavBar from "./NavBar/NavBar";
import AppFab from "./AppFab/AppFab";
import AppContext from "./AppContext";
import PatientService from "./services/PatientService";
import UserService from "./services/UserService";
import Tracker, { initTracking, setTrackedUser } from "./Tracker/Tracker";
import Routes from "./Router/Routes";

if (process.env.hasOwnProperty("REACT_APP_BASE_URL")) {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
}

const userData = JSON.parse(localStorage.getItem("user"));

if (userData) {
  if (userData.token)
    axios.defaults.headers.common = {
      Authorization: `Token ${userData.token}`,
      "Content-Type": "application/json",
    };
}

const useStyles = makeStyles((theme) => ({
  app: {
    paddingTop: theme.spacing(9),
  },
}));

function App() {
  const [loggedUser, setLoggedUser] = useState(userData);
  const [showDrawer, setShowDrawer] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [userList, setUserList] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTrackedUser(loggedUser);
    if (loggedUser) {
      Promise.all([PatientService.all(), UserService.all()])
        .then(([patients, users]) => {
          setPatientList(patients);
          setUserList(users);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [loggedUser]);

  const getPatient = (id) => {
    return patientList.find((patient) => parseInt(patient.id) === parseInt(id));
  };

  const getUser = (id) => {
    return userList.find((user) => parseInt(user.id) === parseInt(id));
  };

  const classes = useStyles();

  if (loggedUser && isLoading) return <LinearProgress />;

  return (
    <AppContext.Provider
      value={{
        loggedUser,
        setLoggedUser,
        patientList,
        getPatient,
        userList,
        getUser,
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Tracker user={loggedUser}>
            <DrawerContext.Provider
              value={{
                showDrawer,
                setShowDrawer,
              }}
            >
              <NavBar />
              {loggedUser && (
                <>
                  <AppDrawer />
                  <AppFab />
                </>
              )}
            </DrawerContext.Provider>

            <Container maxWidth="sm" className={classes.app}>
              <Routes />
            </Container>
          </Tracker>
        </Router>
      </MuiPickersUtilsProvider>
    </AppContext.Provider>
  );
}

export default App;
