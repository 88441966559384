import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

let initialized = false;

export function initTracking(user) {
  if (process.env.hasOwnProperty("REACT_APP_GA_TRACKING_CODE")) {
    const userId = user ? user.user_id : 0;
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, {
      gaOptions: {
        userId,
      },
    });
    initialized = true;
  }
}

export function setTrackedUser(user) {
  const userId = user ? user.user_id : 0;
  ReactGA.set({ userId });
}

export function trackPage(page, search = "") {
  if (!initialized) return console.debug("trackPage", page, "not initialized");
  ReactGA.pageview(page + search);
}

/**
 * 
 * @param {object} event
 * @param {string} event.category
 * @param {string} event.action
 * @param {string} [event.label]
 * @param {number} [event.value]
 * @param {boolean} [event.nonInteraction]
 * @example 
 * trackEvent({
        category: 'Promotion',
        action: 'Displayed Promotional Widget',
        label: 'Homepage Thing',
        nonInteraction: true
      })
 */
export function trackEvent(event) {
  if (!initialized)
    return console.debug("trackEvent", event, "not initialized");
  ReactGA.event(event);
}

export default function Tracker(props) {
  const { children, user } = props;

  const location = useLocation();

  useEffect(() => {
    initTracking(user);
  }, []);

  useEffect(() => {
    if (location) trackPage(location.pathname, location.search);
  }, [location]);

  return children;
}
