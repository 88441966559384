import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Pie,
  PieChart as RePieChart,
  ResponsiveContainer,
  Cell,
  Legend,
  Tooltip,
} from "recharts";
import { Box } from "@material-ui/core";

import CustomizedDot from "./CustomizedDot";

const renderLegend = (props) => {
  const items = props.payload.map((item, index) => {
    const { value, color, payload } = item;
    const percent = (payload.percent * 100).toFixed(0);
    return (
      <div style={{ marginLeft: "10px" }} key={index}>
        <CustomizedDot cx="10" cy="10" color={color} /> {value}: {percent}%
      </div>
    );
  });
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      justifyContent="center"
      flexDirection="row"
    >
      {items}
    </Box>
  );
};

const percentLabel = (value) => (value * 100).toFixed(0);

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${percentLabel(percent)}%`}
    </text>
  );
};

const valueToColor = {
  emergent: "#FFBB28",
  failed: "#FF8042",
  not_classifiable: "#b3b3b3",
  succeeded: "#00C49F",
};

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  pieChartRef: PropTypes.object,
};

const PieChart = (props) => {
  const { data, pieChartRef, onAnimationsEnd } = props;
  const animationTotal = 1;
  const [animationCount, setAnimationCount] = useState(0);
  const updateAnimationEnd = () => {
    const updatedCount = animationCount + 1;
    setAnimationCount(updatedCount);
    if (animationTotal === updatedCount) {
      onAnimationsEnd();
    }
  };

  useEffect(() => {
    setAnimationCount(0);
  }, [data]);

  const totalValues = useCallback(() => {
    return data.reduce((total, d) => {
      total += d.value;
      return total;
    }, 0);
  }, [data]);

  const tooltipFormatter = (value) => {
    const total = totalValues();
    const percent = (value * 100) / total;
    return `${percent.toFixed(0)}% - ${value}/${total}`;
  };

  if (!data) return null;

  return (
    <ResponsiveContainer width="100%" aspect={1} ref={pieChartRef}>
      <RePieChart width={400} height={400}>
        <Pie
          data={data}
          dataKey="value"
          dataName="name"
          cx="50%"
          cy="50%"
          fill="#000"
          onAnimationEnd={updateAnimationEnd}
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data.map((d) => {
            const color = valueToColor[d.key];
            return <Cell key={d.key} fill={color} />;
          })}
        </Pie>
        <Tooltip formatter={tooltipFormatter} />
        <Legend content={renderLegend} />
      </RePieChart>
    </ResponsiveContainer>
  );
};

PieChart.propTypes = propTypes;

export default PieChart;
