import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Typography, Grid, Divider } from "@material-ui/core";
import { parseISO, differenceInYears } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";

import PatientAvatar from "../PatientAvatar/PatientAvatar";

const avatarSize = 7;
const dividerVerticalSpace = 2;

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(avatarSize),
    width: theme.spacing(avatarSize),
    cursor: "pointer",
  },
  name: {
    cursor: "pointer",
  },
  divider: {
    marginTop: theme.spacing(dividerVerticalSpace),
    marginBottom: theme.spacing(dividerVerticalSpace),
  },
}));

const propTypes = {
  patient: PropTypes.shape({
    date_of_birth: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

function PatientHeader(props) {
  const { patient } = props;
  const dateOfBirth = parseISO(patient.date_of_birth);
  const age = differenceInYears(new Date(), dateOfBirth);
  const classes = useStyles();
  const history = useHistory();

  const goToPatientDetails = () => {
    history.push(`/app/patients/${patient.id}`);
  };

  return (
    <div>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <PatientAvatar
            onClick={goToPatientDetails}
            className={classes.avatar}
            firstName={patient.first_name}
            lastName={patient.last_name}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            onClick={goToPatientDetails}
            className={classes.name}
          >
            {`${patient.first_name} ${patient.last_name}`}
          </Typography>
          {!isNaN(age) && (
            <Typography variant="subtitle1">{age} anni</Typography>
          )}
        </Grid>
      </Grid>

      <Divider className={classes.divider} />
    </div>
  );
}

PatientHeader.propTypes = propTypes;

export default PatientHeader;
