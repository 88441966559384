import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  LinearProgress,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import ChecklistService from "../services/ChecklistService";
import ChecklistExecutionService from "../services/ChecklistExecutionService";
import ChecklistExecutionFilter from "../ChecklistExecutionFilter/ChecklistExecutionFilter";
import {
  getCurrentMonth,
  getCurrentYear,
  formattedDate,
  formattedDistance,
  getYearOptions,
  getMonthOptions,
} from "../utilities/date";
import { secondsToClockTime } from "../utilities/time";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  timeTakenExceeded: {
    color: theme.palette.error.main,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(1),
    boxShadow: "0 0 1px rgba(0, 0, 0, .5)",
  },
}));

const currentYear = getCurrentYear();
const currentMonth = getCurrentMonth();
const availableYears = getYearOptions({ addAllOption: true });
const monthOptions = getMonthOptions({ addAllOption: true });

function ChecklistExecutionList({ patientId }) {
  const classes = useStyles();
  const history = useHistory();

  const [availableChecklists, setAvailableChecklists] = useState([]);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [checklist, setChecklist] = useState("all");
  const [executions, setExecutions] = useState();

  useEffect(() => {
    ChecklistService.all().then((data) => {
      const checklist = data || [];
      checklist.unshift({
        id: "all",
        title: "Tutte le prove",
      });
      setAvailableChecklists(checklist);
    });
  }, []);

  useEffect(() => {
    setExecutions(null);
    const params = { patient: patientId };
    if (month !== "all") params.month = month;
    if (year !== "all") params.year = year;
    if (checklist !== "all") params.checklist = checklist;

    ChecklistExecutionService.getList(params).then((executions) => {
      setExecutions(executions);
    });
  }, [month, year, checklist]);

  const goToDetails = (patientId, checklistId, executionId) => {
    history.push({
      pathname: `/app/patients/${patientId}/checklists/${checklistId}/executions/${executionId}`,
    });
  };

  return (
    <>
      <Typography variant="h6">Prove eseguite</Typography>
      <ChecklistExecutionFilter
        month={month}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
        availableYears={availableYears}
        checklist={checklist}
        setChecklist={setChecklist}
        availableChecklists={availableChecklists}
        availableMonths={monthOptions}
      />
      {!executions ? (
        <LinearProgress />
      ) : (
        <List>
          {executions.map((execution) => {
            const { checklist } = execution;
            let { cover } = checklist;
            if (cover && process.env.hasOwnProperty("REACT_APP_BASE_URL")) {
              cover = process.env.REACT_APP_BASE_URL + cover;
            }
            const startDateStr = `${formattedDistance(
              execution.start_time
            )} (${formattedDate(execution.start_time)})`;
            let timeTaken = null;
            let timeTakenClass = {};
            if (execution.time_taken) {
              timeTaken = secondsToClockTime(execution.time_taken);
              if (
                checklist.estimated_time &&
                execution.time_taken > checklist.estimated_time * 60
              ) {
                timeTakenClass = classes.timeTakenExceeded;
              }
            }
            return (
              <span key={execution.id}>
                <ListItem
                  alignItems="flex-start"
                  button
                  onClick={() => {
                    goToDetails(
                      execution.patient,
                      execution.checklist.id,
                      execution.id
                    );
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      alt={checklist.title}
                      src={cover}
                      className={classes.avatar}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={execution.checklist.title}
                    secondary={startDateStr}
                  />
                  {timeTaken && (
                    <span className={timeTakenClass}>{timeTaken}</span>
                  )}
                </ListItem>
                <Divider variant="inset" component="li" />
              </span>
            );
          })}
        </List>
      )}
    </>
  );
}

export default ChecklistExecutionList;
