import axios from 'axios';

export default class ChecklistService {
   static async all() {
      const url = `/api/checklists/`;
      const response = await axios.get(url);
      return response.data;
   }

   static async get(pk) {
      const url = `/api/checklists/${pk}/`;
      const response = await axios.get(url);
      return response.data;
   }

   static async save(title, estimatedTime, tasks, category) {
      const url = `/api/checklists/`;
      const data = {
         title,
         tasks: tasks.map(task => ({ title: task.title })),
         category_id: category || ''
      };
      if (estimatedTime) data.estimated_time = estimatedTime;
      const response = await axios.post(url, data);
      return response.data;
   }

   static async update(id, title, estimatedTime, tasks, category) {
      const url = `/api/checklists/${id}/`;
      const data = {
         title,
         tasks: tasks.map(task => ({ title: task.title, id: task.id }))
      };

      if (estimatedTime) data.estimated_time = estimatedTime;
      if (category) data.category_id = category;

      return await axios.put(url, data);
   }

   static async updateCover(id, cover) {
      const url = `/api/checklists/${id}/`;

      let data = new FormData();
      if (cover) data.append('cover', cover, cover.name);

      return await axios.patch(url, data, {
         headers: {
            'content-type': 'multipart/form-data'
         }
      });
   }

   static async delete(id) {
      const url = `/api/checklists/${id}/`;
      return await axios.delete(url);
   }
}
