import React from "react";
import PropTypes from "prop-types";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  ResponsiveContainer,
} from "recharts";

import { blue, rustyRed } from "../theme";

CustomBarChart.propTypes = {
  data: PropTypes.array,
  fill: PropTypes.string,
};

CustomBarChart.defaultProps = {
  fill: blue,
};

function CustomBarChart(props) {
  const { data, fill } = props;

  return (
    <ResponsiveContainer width="100%" aspect={1.5}>
      <ComposedChart
        margin={{ top: 20, right: 20, left: 0, bottom: 20 }}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" scale="band" />
        <YAxis />
        <Bar dataKey="count" barSize={20} fill={fill} />
        <Line type="monotone" dataKey="count" stroke={rustyRed} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default CustomBarChart;
