import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import AppContext from '../AppContext';
import { DateTimePicker } from '@material-ui/pickers';
import itLocale from 'date-fns/locale/it';
import { dateTimeFormat } from '../utilities/date';

const propTypes = {
   values: PropTypes.shape({
      patient: PropTypes.number,
      checklist: PropTypes.number,
      user: PropTypes.number,
      date: PropTypes.object
   }).isRequired,
   setValues: PropTypes.func.isRequired,
   checklists: PropTypes.array.isRequired
};

const defaultProps = {
   onChecklistChange: () => {}
};

function Form({ values, setValues, checklists, children, onChecklistChange }) {
   const { patientList, userList } = useContext(AppContext);
   const setValue = value => {
      setValues({ ...values, ...value });
   };

   return (
      <>
         <form autoComplete="off">
            <TextField
               id="patient"
               select
               fullWidth
               variant="outlined"
               label="Utente"
               placeholder="Seleziona un utente"
               margin="normal"
               value={values.patient || ''}
               required
               onChange={e => {
                  setValue({
                     patient: parseInt(e.target.value)
                  });
               }}
            >
               {patientList.map(option => {
                  const selected = values.patient === option.id;
                  return (
                     <MenuItem
                        key={option.id}
                        value={option.id}
                        selected={selected}
                     >
                        {`${option.first_name} ${option.last_name}`}
                     </MenuItem>
                  );
               })}
            </TextField>

            <TextField
               id="checklist"
               select
               fullWidth
               variant="outlined"
               label="Prova"
               placeholder="Seleziona una prova"
               margin="normal"
               required
               value={values.checklist || checklists}
               onChange={e => {
                  const value = parseInt(e.target.value);
                  setValue({
                     checklist: value
                  });
                  onChecklistChange(value);
               }}
            >
               {checklists.map(option => {
                  const selected = values.checklist === option.id;
                  return (
                     <MenuItem
                        key={option.id}
                        value={option.id}
                        selected={selected}
                     >
                        {option.title}
                     </MenuItem>
                  );
               })}
            </TextField>

            <TextField
               id="user"
               select
               fullWidth
               variant="outlined"
               label="Operatore"
               placeholder="Seleziona un operatore"
               margin="normal"
               required
               value={values.user || ''}
               onChange={e => {
                  setValue({ user: parseInt(e.target.value) });
               }}
            >
               {userList.map(option => {
                  const selected = values.user === option.id;
                  return (
                     <MenuItem
                        key={option.id}
                        value={option.id}
                        selected={selected}
                     >
                        {`${option.first_name} ${option.last_name}`}
                     </MenuItem>
                  );
               })}
            </TextField>

            <DateTimePicker
               locale={itLocale}
               fullWidth
               inputVariant="outlined"
               ampm={false}
               format={dateTimeFormat}
               margin="normal"
               label="Data e Ora inizio"
               value={values.date}
               onChange={value => {
                  setValue({
                     date: value
                  });
               }}
            />
            {children}
         </form>
      </>
   );
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
