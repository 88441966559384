import {
  parseISO,
  format,
  formatDistanceToNow,
  getYear,
  getMonth,
} from "date-fns";
import { it } from "date-fns/locale";

export const dateTimeFormat = "dd/MM/yyyy HH:mm:ss";
export const serverDateTimeFormat = "yyyy-MM-dd HH:mm:ss";

export const availableMonthOptions = [
  { value: 1, label: "Gennaio" },
  { value: 2, label: "Febbraio" },
  { value: 3, label: "Marzo" },
  { value: 4, label: "Aprile" },
  { value: 5, label: "Maggio" },
  { value: 6, label: "Giugno" },
  { value: 7, label: "Luglio" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Settembre" },
  { value: 10, label: "Ottobre" },
  { value: 11, label: "Novembre" },
  { value: 12, label: "Dicembre" },
];

/**
 *
 * @param {Object} [settings]
 * @param {bool}   [settings.addAllOption] If true add an option to select "all years"
 */
export function getMonthOptions(settings = {}) {
  const defaultSettings = { addAllOption: false };
  settings = { ...defaultSettings, ...settings };
  let options = [];
  if (settings.addAllOption)
    options.push({ value: "all", label: "Tutti i mesi" });
  options = [...options, ...availableMonthOptions];
  return options;
}

/**
 *
 * @param {Object} [settings]
 * @param {number} [settings.limit] The number of years to include in the list starting from the nearest
 * @param {bool}   [settings.addAllOption] If true add an option to select "all years"
 */
export function getYearOptions(settings = {}) {
  const currentYear = getCurrentYear();
  const defaultSettings = { limit: 10, addAllOption: false };
  settings = { ...defaultSettings, ...settings };
  const options = [];
  if (settings.addAllOption)
    options.push({
      value: "all",
      label: "Tutti gli anni",
    });
  for (let y = currentYear; y >= currentYear - settings.limit; y--) {
    options.push({
      value: y,
      label: y,
    });
  }
  return options;
}

export function getCurrentYear() {
  return getYear(new Date());
}

export function getCurrentMonth() {
  return getMonth(new Date()) + 1;
}

export function dateFromServer(serverDate) {
  return parseISO(serverDate.slice(0, -1));
}

export function formattedDate(serverDate, dateFormat) {
  dateFormat = dateFormat || dateTimeFormat;
  const date = dateFromServer(serverDate);
  return format(date, dateFormat, {
    locale: it,
  });
}

export function formattedDistance(isoDate) {
  const date = parseISO(isoDate.slice(0, -1));
  return formatDistanceToNow(date, {
    addSuffix: true,
    locale: it,
  });
}

export function weekDayFromNumberToString(weekDay, short = false) {
  const weekDayStrings = [
    "Domenica",
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
  ];
  const weekDayShortStrings = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"];
  const list = short ? weekDayShortStrings : weekDayStrings;
  return list[weekDay];
}
