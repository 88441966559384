import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import { localizeLabel } from "../utilities/locale";

export default function ResultLinearChart({
  data,
  lineChartRef,
  onAnimationsEnd,
}) {
  const animationTotal = 3;
  const [animationCount, setAnimationCount] = useState(0);
  const updateAnimationEnd = () => {
    const updatedCount = animationCount + 1;
    setAnimationCount(updatedCount);
    if (animationTotal === updatedCount) {
      onAnimationsEnd();
    }
  };

  useEffect(() => {
    setAnimationCount(0);
  }, [data]);

  return (
    <ResponsiveContainer width="100%" aspect={1.5} ref={lineChartRef}>
      <LineChart
        margin={{ top: 20, right: 10, left: 0, bottom: 20 }}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey={localizeLabel("succeeded")}
          stroke="#00C49F"
          activeDot={{ r: 8 }}
          onAnimationEnd={updateAnimationEnd}
        />
        <Line
          type="monotone"
          dataKey={localizeLabel("emergent")}
          stroke="#FFBB28"
          activeDot={{ r: 8 }}
          onAnimationEnd={updateAnimationEnd}
        />
        <Line
          type="monotone"
          dataKey={localizeLabel("failed")}
          stroke="#FF8042"
          activeDot={{ r: 8 }}
          onAnimationEnd={updateAnimationEnd}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
