import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { IconButton } from "@material-ui/core";
import { useParams } from "react-router-dom";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import domtoimage from "dom-to-image";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import AppContext from "../AppContext";

const styles = StyleSheet.create({
  page: {},
  section: {
    margin: 20,
    padding: 40,
  },
});

const createAndDownloadPDF = (pdfContent, filename, divId, callback) => {
  setTimeout(() => {
    const link = (
      <div id={divId}>
        <PDFDownloadLink
          document={pdfContent}
          fileName={filename}
        ></PDFDownloadLink>
      </div>
    );
    const elem = document.createElement("div");
    document.getElementById("root").appendChild(elem);
    ReactDOM.render(link, elem);
    setTimeout(() => {
      document.getElementById(divId).children[0].click();
      elem.remove();
      callback();
    }, 1);
  }, 1);
};

export default function GoalResultStatisticsPDF({
  lineChartRef,
  barChartRef,
  pieChartRef,
  disabled,
  activeFilters,
}) {
  const { patientId } = useParams();
  const appContext = useContext(AppContext);
  const patient = appContext.getPatient(patientId);
  const patientFullName = `${patient.first_name} ${patient.last_name}`;
  let fileName = `statistiche_obiettivi-${patientFullName}-${activeFilters.goal}-${activeFilters.month.label}-${activeFilters.year.value}.pdf`;
  fileName = fileName.replaceAll(" ", "_");
  const [loadingPDF, setLoadingPDF] = useState();

  const buildPDF = () => {
    if (!loadingPDF) {
      setLoadingPDF(true);
      const lineChartNode = lineChartRef.current.current;
      const barChartNode = barChartRef.current.current;
      const pieChartNode = pieChartRef.current.current;

      Promise.all([
        domtoimage.toPng(lineChartNode),
        domtoimage.toPng(barChartNode),
        domtoimage.toPng(pieChartNode),
      ]).then(([lineChartUrl, barChartUrl, pieChartUrl]) => {
        const Report = () => (
          <Document
            author="skillseducation.app"
            title={`Statistiche ${patientFullName}`}
            subject="Statistiche"
          >
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text>
                  {patientFullName} - {activeFilters.goal}
                </Text>
                <Text>
                  {activeFilters.month.label} {activeFilters.year.label}
                </Text>
                <Image src={lineChartUrl} />
                <Image src={barChartUrl} />
                <Image src={pieChartUrl} />
              </View>
            </Page>
          </Document>
        );
        createAndDownloadPDF(<Report />, fileName, "pdf-creator-link", () =>
          setLoadingPDF(false)
        );
      });
    }
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        aria-label="Scarica PDF"
        onClick={buildPDF}
      >
        <PictureAsPdfIcon />
      </IconButton>
    </>
  );
}
