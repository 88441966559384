import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
   LinearProgress,
   Typography,
   Button,
   Tabs,
   Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { serverDateTimeFormat } from '../utilities/date';

// services
import ChecklistService from '../services/ChecklistService';
import ChecklistExecutionService from '../services/ChecklistExecutionService';

// utilities
import { dateFromServer } from '../utilities/date';

// components
import Form from '../ChecklistSubmit/Form';
import Slider from '../ChecklistCompilation/Slider';
import Timer from '../Timer/Timer';

function TabPanel(props) {
   const { children, value, index } = props;
   return value === index && children;
}

const useStyles = makeStyles(theme => ({
   submitBtn: {
      marginTop: theme.spacing(3)
   }
}));

export default function ChecklistExecutionEdit() {
   const history = useHistory();
   const { executionId } = useParams();
   const [activeTab, setActiveTab] = useState(0);
   const [checklists, setChecklists] = useState([]);
   const [execution, setExecution] = useState();
   const [tasks, setTasks] = useState();
   const [taskResults, setTaskResults] = useState({});
   const [time, setTime] = useState(0);
   const [activeStep, setActiveStep] = useState(0);
   const [isLoading, setIsLoading] = useState(true);
   const [values, setValues] = useState();
   const classes = useStyles();

   const handleSubmit = () => {
      const startTime = format(values.date, serverDateTimeFormat);
      const taskResultList = Object.keys(taskResults).map(id => {
         const value = taskResults[id].value;
         return {
            task_id: id,
            value
         };
      });
      const data = {
         checklist: values.checklist,
         user: values.user,
         patient: values.patient,
         start_time: startTime,
         task_results: taskResultList,
         time_taken: time
      };
      ChecklistExecutionService.update(executionId, data).then(response => {
         if (response.status === 200)
            history.push(
               `/app/patients/${response.data.patient}/checklists/${response.data.checklist}/executions/${response.data.execution}`
            );
      });
   };

   const taskListToDictionary = results => {
      return results.reduce((dict, result) => {
         const { task } = result;
         dict[task.id] = {
            id: result.id,
            value: result.value
         };
         return dict;
      }, {});
   };

   const handleChecklistChange = value => {
      const newChecklist = checklists.find(checklist => checklist.id === value);
      setTaskResults({});
      setTasks(newChecklist.tasks);
   };

   useEffect(() => {
      Promise.all([
         ChecklistService.all(),
         ChecklistExecutionService.get(executionId)
      ]).then(([checklists, execution]) => {
         setChecklists(checklists);
         setExecution(execution);
         setValues({
            checklist: parseInt(execution.checklist.id),
            patient: parseInt(execution.patient),
            user: parseInt(execution.user.id),
            date: dateFromServer(execution.start_time)
         });
         setTime(execution.time_taken);
         setTaskResults(taskListToDictionary(execution.task_results));
         setTasks(execution.checklist.tasks);
         setIsLoading(false);
      });
   }, []);

   if (isLoading) return <LinearProgress />;
   return (
      <>
         <Typography variant="h6">Modifica prova</Typography>
         <Tabs
            value={activeTab}
            onChange={(event, newValue) => {
               setActiveTab(newValue);
            }}
            indicatorColor="primary"
            textColor="primary"
            centered
         >
            <Tab label="Compilazione" />
            <Tab label="Risultati" />
         </Tabs>

         <TabPanel index={0} value={activeTab}>
            <Form
               values={values}
               setValues={setValues}
               handleSubmit={handleSubmit}
               checklists={checklists}
               onChecklistChange={handleChecklistChange}
            />
         </TabPanel>

         <TabPanel index={1} value={activeTab}>
            <Slider
               activeStep={activeStep}
               setActiveStep={setActiveStep}
               tasks={tasks}
               taskResults={taskResults}
               setTaskResults={setTaskResults}
            />
            <Timer time={time} setTime={setTime} />
         </TabPanel>

         <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            className={classes.submitBtn}
         >
            Salva
         </Button>
      </>
   );
}
