import React, { useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  InputAdornment,
  TextField,
  MenuItem,
  Grid,
  IconButton,
} from "@material-ui/core";
import MagnifyIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import debounce from "lodash.debounce";

import { availableMonthOptions } from "../utilities/date";

const propTypes = {
  month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setMonth: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setYear: PropTypes.func.isRequired,
  patient: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setPatient: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setUser: PropTypes.func.isRequired,
  availableYears: PropTypes.array,
  availableMonths: PropTypes.array,
  availableUsers: PropTypes.array,
  availablePatients: PropTypes.array,
  showNoteFilter: PropTypes.bool,
};

const defaultProps = {
  availableYears: [],
  availableUsers: [],
  availablePatients: [],
  availableMonths: availableMonthOptions,
};

function HandoverFilter({
  month,
  setMonth,
  year,
  setYear,
  patient,
  setPatient,
  user,
  setUser,
  setNote,
  availableYears,
  availableMonths,
  availableUsers,
  availablePatients,
  showNoteFilter,
}) {
  const noteFilterRef = useRef(null);

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handlePatientChange = (e) => {
    setPatient(e.target.value);
  };

  const debouncedSetNote = debounce(setNote, 500);

  const handleNoteFilterChange = (e) => {
    const value = e.target.value;
    debouncedSetNote(value);
  };

  const emptyNoteFilter = () => {
    noteFilterRef.current.value = "";
    handleNoteFilterChange({
      target: { value: "" },
    });
  };

  return (
    <>
      <form autoComplete="off">
        <Grid container spacing={2}>
          {showNoteFilter && (
            <Grid item xs={12}>
              <TextField
                id="note"
                fullWidth
                label="Cerca nelle note"
                margin="normal"
                variant="outlined"
                onChange={handleNoteFilterChange}
                inputRef={noteFilterRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagnifyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="Svuota"
                        onClick={emptyNoteFilter}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              id="month"
              select
              fullWidth
              label="Mese"
              margin="normal"
              value={month}
              onChange={handleMonthChange}
            >
              {availableMonths.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="year"
              select
              fullWidth
              label="Anno"
              margin="normal"
              value={year}
              onChange={handleYearChange}
            >
              {availableYears.map((year) => (
                <MenuItem value={year.value} key={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              id="user"
              select
              fullWidth
              label="Operatore"
              margin="normal"
              value={user}
              onChange={handleUserChange}
            >
              {availableUsers.map(({ id, label }) => (
                <MenuItem value={id} key={id}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="patient"
              select
              fullWidth
              label="Utente"
              margin="normal"
              value={patient}
              onChange={handlePatientChange}
            >
              {availablePatients.map(({ id, label }) => (
                <MenuItem value={id} key={id}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

HandoverFilter.propTypes = propTypes;
HandoverFilter.defaultProps = defaultProps;

export default HandoverFilter;
