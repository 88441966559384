import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { Typography, TextField } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import FlagIcon from "@material-ui/icons/Flag";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import AppContext from "../AppContext";
import PatientAvatar from "../PatientAvatar/PatientAvatar";

function PatientList() {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const { patientList } = appContext;
  const [filter, setFilter] = useState("");

  const filteredPatientList =
    filter === ""
      ? patientList
      : patientList.filter((patient) => {
          const patientFullName =
            `${patient.first_name} ${patient.last_name}`.toLowerCase();
          const filterLowerCase = filter.toLowerCase();
          return patientFullName.includes(filterLowerCase);
        });

  const patientItems = filteredPatientList.map((patient) => {
    const goToChecklistSubmit = () => {
      history.push(`/app/checklist-submit?patient=${patient.id}`);
    };
    const goToDetails = () => {
      history.push(`/app/patients/${patient.id}`);
    };
    const goToGoalResultRegistration = () => {
      history.push(`/app/patients/${patient.id}/goal-results/registration`);
    };
    const goToChallengingBehaviorRegistration = () => {
      history.push(
        `/app/challenging-behavior-registration?patient=${patient.id}`
      );
    };

    return (
      <span key={patient.id}>
        <ListItem button onClick={goToDetails}>
          <ListItemAvatar>
            <PatientAvatar
              firstName={patient.first_name}
              lastName={patient.last_name}
            />
          </ListItemAvatar>
          <ListItemText
            primary={`${patient.last_name} ${patient.first_name}`}
          />
          <ListItemSecondaryAction>
            {/* <IconButton
              edge="end"
              aria-label="vedi dettaglio"
              onClick={goToDetails}
            >
              <PersonIcon />
            </IconButton> */}
            {/* <IconButton
              edge="end"
              aria-label="statistiche"
              onClick={goToStatistics}
            >
              <BarChartIcon />
            </IconButton> */}
            <IconButton
              edge="end"
              aria-label="registra l'esito di un obiettivo"
              onClick={goToGoalResultRegistration}
            >
              <FlagIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="compila una checklist"
              onClick={goToChecklistSubmit}
            >
              <PlayCircleOutlineIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="registra un comportamento problema"
              onClick={goToChallengingBehaviorRegistration}
            >
              <ErrorOutline />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </span>
    );
  });

  const handleFilterChange = (e) => {
    const { value } = e.currentTarget;
    setFilter(value);
  };

  return (
    <>
      <Typography variant="h6">Utenti</Typography>
      <TextField
        id="filter"
        label="Filtra elenco"
        value={filter}
        onChange={handleFilterChange}
        fullWidth
      />
      <List>{patientItems}</List>
    </>
  );
}

export default PatientList;
