import React, { useEffect, useRef } from 'react';
import {
   Button,
   Typography,
   Stepper,
   Step,
   StepLabel,
   StepButton,
   Card,
   CardContent
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StepIcon from './StepIcon';

const SucceededButton = withStyles(theme => ({
   root: {
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
      '&:hover': {
         backgroundColor: theme.palette.success.light
      }
   }
}))(Button);

const EmergentButton = withStyles(theme => ({
   root: {
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
         backgroundColor: theme.palette.warning.light
      }
   }
}))(Button);

const FailedButton = withStyles(theme => ({
   root: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
         backgroundColor: theme.palette.error.light
      }
   }
}))(Button);

const useStyles = makeStyles(theme => ({
   submitBtn: {
      marginTop: theme.spacing(3)
   },
   stepper: {
      overflowY: 'auto'
   },
   card: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
   },
   taskTitle: {
      height: 80,
      overflowY: 'auto'
   },
   buttons: {
      padding: theme.spacing(2)
   },
   navigationButtons: {
      display: 'flex',
      justifyContent: 'space-between'
   },
   mainActions: {
      display: 'flex',
      justifyContent: 'space-between'
   },
   notClassifiable: {
      marginTop: theme.spacing(2)
   }
}));

export default function Slider({
   activeStep,
   setActiveStep,
   tasks,
   taskResults,
   setTaskResults
}) {
   const stepperRef = useRef();
   const classes = useStyles();

   useEffect(() => {
      const { current } = stepperRef;
      if (current) {
         const active = current.getElementsByClassName('active');
         active[0].scrollIntoView({
            behavior: 'smooth',
            inline: 'center'
         });
      }
   }, [activeStep]);

   const nextStep = () => {
      const nextIndex = activeStep + 1;
      if (nextIndex < tasks.length) setActiveStep(nextIndex);
   };
   const previousStep = () => {
      const previousIndex = activeStep - 1;
      if (previousIndex >= 0) setActiveStep(previousIndex);
   };

   const handleStepClick = index => {
      setActiveStep(index);
   };

   const setTaskResult = (id, result) => {
      const newValues = {
         ...taskResults
      };
      newValues[id] = {
         value: result
      };
      setTaskResults(newValues);
   };

   const activeTask = tasks[activeStep];

   return (
      <>
         <Stepper
            nonLinear
            activeStep={activeStep}
            className={classes.stepper}
            ref={stepperRef}
         >
            {tasks.map((task, index) => {
               const taskResult = taskResults[task.id];
               const isCompleted = !!taskResult;

               return (
                  <Step key={task.id}>
                     <StepButton
                        onClick={() => handleStepClick(index)}
                        completed={isCompleted}
                     >
                        <StepLabel
                           StepIconComponent={StepIcon}
                           StepIconProps={{
                              taskResult
                           }}
                        />
                     </StepButton>
                  </Step>
               );
            })}
         </Stepper>

         <Card className={classes.card}>
            <CardContent>
               <Typography variant="h5" className={classes.taskTitle}>
                  {' '}
                  {activeStep + 1}. {activeTask.title}
               </Typography>
            </CardContent>
            <div className={classes.buttons}>
               <div className={classes.mainActions}>
                  <SucceededButton
                     variant="contained"
                     onClick={() => {
                        setTaskResult(activeTask.id, 'succeeded');
                        nextStep();
                     }}
                  >
                     Riuscito
                  </SucceededButton>
                  <EmergentButton
                     variant="contained"
                     onClick={() => {
                        setTaskResult(activeTask.id, 'emergent');
                        nextStep();
                     }}
                  >
                     Emergente
                  </EmergentButton>
                  <FailedButton
                     variant="contained"
                     onClick={() => {
                        setTaskResult(activeTask.id, 'failed');
                        nextStep();
                     }}
                  >
                     Fallito
                  </FailedButton>
               </div>
               <Button
                  variant="outlined"
                  className={classes.notClassifiable}
                  fullWidth
                  onClick={() => {
                     setTaskResult(activeTask.id, 'not_classifiable');
                     nextStep();
                  }}
               >
                  Non Classificabile
               </Button>
            </div>
         </Card>

         <div className={classes.navigationButtons}>
            <Button
               variant="text"
               onClick={previousStep}
               disabled={activeStep === 0}
            >
               Precedente
            </Button>
            <Button
               variant="text"
               onClick={nextStep}
               disabled={activeStep === tasks.length - 1}
            >
               Successivo
            </Button>
         </div>
      </>
   );
}
