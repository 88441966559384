import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import { Box } from "@material-ui/core";

import CustomizedDot from "./CustomizedDot";
import { localizeLabel } from "../utilities/locale";

const renderLegend = (props) => {
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      justifyContent="center"
      flexDirection="row"
    >
      <div style={{ marginLeft: "20px" }}>
        <CustomizedDot value={1} cx="10" cy="10" /> Fallito
      </div>
      <div style={{ marginLeft: "20px" }}>
        <CustomizedDot value={2} cx="10" cy="10" /> Emergente
      </div>
      <div style={{ marginLeft: "20px" }}>
        <CustomizedDot value={3} cx="10" cy="10" /> Riuscito
      </div>
    </Box>
  );
};

const CustomTooltip = (props) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    const result = localizeLabel(payload[0].payload.value);
    return (
      <Box p={1} bgcolor="common.white">
        {label}: {result}
      </Box>
    );
  }

  return null;
};

export default function LinearChart({ data, lineChartRef, onAnimationsEnd }) {
  const animationTotal = 1;
  const [animationCount, setAnimationCount] = useState(0);
  const updateAnimationEnd = () => {
    const updatedCount = animationCount + 1;
    setAnimationCount(updatedCount);
    if (animationTotal === updatedCount) {
      onAnimationsEnd();
    }
  };

  useEffect(() => {
    setAnimationCount(0);
  }, [data]);

  return (
    <ResponsiveContainer width="100%" aspect={1.5} ref={lineChartRef}>
      <LineChart
        margin={{ top: 20, right: 10, left: 0, bottom: 20 }}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis dataKey={localizeLabel("result")} allowDecimals={false} />
        <Legend content={renderLegend} />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey={localizeLabel("result")}
          stroke="#199DF0"
          activeDot={{ r: 8 }}
          onAnimationEnd={updateAnimationEnd}
          dot={<CustomizedDot />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
