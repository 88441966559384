import React, { useContext, useState, useEffect } from "react";
import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  BottomNavigation,
  BottomNavigationAction,
  Tabs,
  Tab,
} from "@material-ui/core";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import FlagIcon from "@material-ui/icons/Flag";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";

import ChecklistExecutionList from "../ChecklistExecutionList/ChecklistExecutionList";
import ChecklistStatistics from "../ChecklistStatistics/ChecklistStatistics";
import AppContext from "../AppContext";
import PatientHeader from "../PatientHeader/PatientHeader";
import GoalResultList from "../GoalResultList/GoalResultList";
import GoalResultStatistics from "../GoalResultStatistics/GoalResultStatistics";
import ChallengingBehaviorRegistrationList from "../ChallengingBehaviorRegistrationList/ChallengingBehaviorRegistrationList";
import ChallengingBehaviorStatistics from "../ChallengingBehaviorStatistics/ChallengingBehaviorStatistics";
import HandoverList from "../HandoverList/HandoverList";

const dividerVerticalSpace = 2;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(12),
  },
  divider: {
    marginTop: theme.spacing(dividerVerticalSpace),
    marginBottom: theme.spacing(dividerVerticalSpace),
  },
  button: {
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  bottomNavigation: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  topNavigation: {
    marginBottom: theme.spacing(2),
  },
}));

export default function PatientDetails(props) {
  const { match, location } = props;
  const history = useHistory();
  const [activePrimaryTab, setActivePrimaryTab] = useState(0);
  const [activeSecondaryTab, setActiveSecondaryTab] = useState(0);
  const { patientId } = match.params;
  const appContext = useContext(AppContext);
  const patient = appContext.getPatient(patientId);

  useEffect(() => {
    if (location.pathname.includes("challenging-behaviors")) {
      setActivePrimaryTab(1);
    } else if (location.pathname.includes("checklist-executions")) {
      setActivePrimaryTab(2);
    } else if (location.pathname.includes("handovers")) {
      setActivePrimaryTab(3);
    } else if (activePrimaryTab !== 0) {
      setActivePrimaryTab(0);
    }

    if (location.pathname.includes("/statistics")) {
      setActiveSecondaryTab(1);
    } else if (activeSecondaryTab !== 0) {
      setActiveSecondaryTab(0);
    }
  }, [location.pathname]);

  const classes = useStyles();

  const goToGoalResults = () => {
    let url = `/app/patients/${patient.id}/goal-results`;
    if (activeSecondaryTab === 1) {
      url += "/statistics";
    }
    history.push(url);
  };

  const goToChallengingBehaviors = () => {
    let url = `/app/patients/${patient.id}/challenging-behaviors`;
    if (activeSecondaryTab === 1) {
      url += "/statistics";
    }
    history.push(url);
  };

  const goToChecklistExecutions = () => {
    let url = `/app/patients/${patient.id}/checklist-executions`;
    if (activeSecondaryTab === 1) {
      url += "/statistics";
    }
    history.push(url);
  };

  const goToHandovers = () => {
    let url = `/app/patients/${patient.id}/handovers`;
    history.push(url);
  };

  const goToHistory = () => {
    // remove '/statistics' from url and redirect
    const { pathname } = location;
    history.push(pathname.replace(/\/statistics$/, ""));
  };

  const goToStatistics = () => {
    // add '/statistics' from url and redirect
    history.push(location.pathname + "/statistics");
  };

  const handleSecondaryTabChange = (event, newValue) => {
    setActiveSecondaryTab(newValue);
    if (newValue === 0) {
      goToHistory();
    } else if (newValue === 1) {
      goToStatistics();
    }
  };

  const showTabs = !location.pathname.includes("/handovers");

  return (
    <div className={classes.root}>
      <PatientHeader patient={patient} />

      {showTabs && (
        <Tabs
          value={activeSecondaryTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleSecondaryTabChange}
          centered
          variant="fullWidth"
          className={classes.topNavigation}
        >
          <Tab label="Storico" />
          <Tab label="Statistiche" />
        </Tabs>
      )}

      <Switch>
        <Route path="/app/patients/:patientId" exact>
          <Redirect to={`/app/patients/${patientId}/goal-results`} />
        </Route>
        <Route path="/app/patients/:patientId/goal-results" exact>
          <GoalResultList match={match} />
        </Route>
        <Route path="/app/patients/:patientId/goal-results/statistics" exact>
          <GoalResultStatistics match={match} />
        </Route>
        <Route path="/app/patients/:patientId/challenging-behaviors" exact>
          <ChallengingBehaviorRegistrationList match={match} />
        </Route>
        <Route
          path="/app/patients/:patientId/challenging-behaviors/statistics"
          exact
        >
          <ChallengingBehaviorStatistics match={match} />
        </Route>
        <Route path="/app/patients/:patientId/checklist-executions" exact>
          <ChecklistExecutionList patientId={patient.id} />
        </Route>
        <Route
          path="/app/patients/:patientId/checklist-executions/statistics"
          exact
        >
          <ChecklistStatistics match={match} />
        </Route>
        <Route path="/app/patients/:patientId/handovers" exact>
          <HandoverList match={match} />
        </Route>
      </Switch>

      <BottomNavigation
        value={activePrimaryTab}
        onChange={(event, newValue) => {
          setActivePrimaryTab(newValue);
        }}
        showLabels
        className={classes.bottomNavigation}
      >
        <BottomNavigationAction
          label="Obiettivi"
          icon={<FlagIcon />}
          onClick={goToGoalResults}
        />
        <BottomNavigationAction
          label="CP"
          icon={<ErrorOutline />}
          onClick={goToChallengingBehaviors}
        />
        <BottomNavigationAction
          label="Prove"
          icon={<PlayCircleOutlineIcon />}
          onClick={goToChecklistExecutions}
        />
        <BottomNavigationAction
          label="Consegne"
          icon={<NoteOutlinedIcon />}
          onClick={goToHandovers}
        />
      </BottomNavigation>
    </div>
  );
}
