import React from "react";
import PropTypes from "prop-types";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

GoalResultOverallRadar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      goal: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
    })
  ).isRequired,
};

function GoalResultOverallRadar(props) {
  const { data } = props;
  return (
    <div>
      <ResponsiveContainer width="100%" aspect={1}>
        <RadarChart
          data={data}
          margin={{ top: 0, right: 60, bottom: 0, left: 60 }}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="goal" />
          <PolarRadiusAxis domain={[0, 100]} />
          <Radar
            dataKey="score"
            stroke="#199DF0"
            fill="#199DF0"
            fillOpacity={0.4}
          />
          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default GoalResultOverallRadar;
