import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import FlagIcon from "@material-ui/icons/Flag";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import { Button } from "@material-ui/core";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import ErrorIcon from "@material-ui/icons/Error";

import packageData from "../../package.json";
import DrawerContext from "./DrawerContext";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  logout: {
    margin: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  version: {
    marginTop: theme.spacing(2),
  },
}));

function AppDrawer() {
  const drawerContext = useContext(DrawerContext);
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const classes = useStyles();
  const history = useHistory();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    drawerContext.setShowDrawer(open);
  };

  function ListItemLink(props) {
    return <ListItem button component={Link} {...props} />;
  }

  function logout() {
    localStorage.removeItem("user");
    history.push("/app/login");
  }

  const Items = (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
      <ListSubheader>UTENTI</ListSubheader>
        <ListItemLink to="/app/patients">
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Lista Utenti" />
        </ListItemLink>
        <Divider />
        <ListSubheader>CONSEGNE</ListSubheader>
        <ListItemLink to="/app/handovers">
          <ListItemIcon>
            <NoteOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Storico Consegne" />
        </ListItemLink>
        <ListItemLink to="/app/handover-creation">
          <ListItemIcon>
            <NoteAddOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Registra Consegna" />
        </ListItemLink>
        <Divider />
        <ListSubheader>OBIETTIVI</ListSubheader>
        <ListItemLink to="/app/goal-results/registration">
          <ListItemIcon>
            <FlagIcon />
          </ListItemIcon>
          <ListItemText primary="Registra un Obiettivo" />
        </ListItemLink>
        <Divider />
        <ListSubheader>C. P.</ListSubheader>
        <ListItemLink to="/app/challenging-behavior-registration">
          <ListItemIcon>
            <ErrorIcon />
          </ListItemIcon>
          <ListItemText primary="Registra un C.P." />
        </ListItemLink>
        <Divider />
        <ListSubheader>PROVE</ListSubheader>
        <ListItemLink to="/app/checklist-submit">
          <ListItemIcon>
            <PlayCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Registra Prova" />
        </ListItemLink>
        <ListItemLink to="/app/checklist-management">
          <ListItemIcon>
            <PlaylistPlayIcon />
          </ListItemIcon>
          <ListItemText primary="Gestisci Prove" />
        </ListItemLink>
        <ListItemLink to="/app/checklist-creation">
          <ListItemIcon>
            <PlaylistAddIcon />
          </ListItemIcon>
          <ListItemText primary="Crea Prova" />
        </ListItemLink>
      </List>
      <Divider />
      <Button variant="outlined" className={classes.logout} onClick={logout}>
        Logout
      </Button>
      <Divider />
      <Typography
        className={classes.version}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        <small>{`version ${packageData.version}`}</small>
      </Typography>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        open={drawerContext.showDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        {Items}
      </SwipeableDrawer>
    </div>
  );
}

export default AppDrawer;
