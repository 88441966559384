import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import UserService from "../services/UserService";
import axios from "axios";
import AppContext from "../AppContext";
import packageData from "../../package.json";

function Copyright() {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        Skills Education {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <small>{`version ${packageData.version}`}</small>
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login() {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const submitLogin = (event) => {
    event.preventDefault();
    setErrors([]);
    UserService.login(username, password).then(
      (response) => {
        if (response.status === 200) {
          const userData = response.data;
          const userDataString = JSON.stringify(userData);
          localStorage.setItem("user", userDataString);
          axios.defaults.headers.common = {
            Authorization: `Token ${userData.token}`,
          };
          appContext.setLoggedUser(userData);
          history.push("/");
        }
      },
      (failed) => {
        const { data } = failed.response;
        const responseErrors = Object.keys(data).reduce((acc, key) => {
          const value = data[key];
          if (Array.isArray(value)) acc = [...acc, ...value];
          else acc.push(value);
          return acc;
        }, []);
        setErrors(responseErrors);
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Accedi all'applicazione
        </Typography>
        <form className={classes.form} noValidate onSubmit={submitLogin}>
          {!!errors.length &&
            errors.map((error) => <Alert severity="error">{error}</Alert>)}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!username || !password}
          >
            Accedi
          </Button>
          {/* <Grid container>
                  <Grid item xs>
                     <Link href="#" variant="body2">
                        Forgot password?
                     </Link>
                  </Grid>
               </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Login;
